import { defineStore, acceptHMRUpdate } from 'pinia';
import { Network } from '@capacitor/network';

interface State {
  isConnected: boolean,
  connectionType: string | null;
  requireConnectionAlert: boolean;
}

export const useNetworkStore = defineStore('network', {

  state: (): State => ({ 
    isConnected: true,
    connectionType: null,
    requireConnectionAlert: false,
  }),

  getters: {
    //
  },

  actions: {

    async updateConnectionStatus(status: any = null) {
      if (!status) {
        status = await Network.getStatus();
      }
      this.isConnected = status.connected;
      this.connectionType = status.connectionType;
    },

    showRequireConnectionAlert() {
      if (!this.requireConnectionAlert) {
        this.requireConnectionAlert = true;
        // auto close
        setTimeout(() => {
          this.hideRequireConnectionAlert();
        }, 10000);
      }
    },

    hideRequireConnectionAlert() {
      this.requireConnectionAlert = false;      
    }
  }
});

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useNetworkStore, import.meta.webpackHot));
}
