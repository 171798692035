import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { InventoryStockHistory } from '@/entity/InventoryStockHistory';

export const mergeInventoryStockHistories = async (businessId: any, locationId: any, inventoryStockHistories: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching inventory stock history data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (inventoryStockHistories && inventoryStockHistories.length > 0) {
    for (const inventoryStockHistory of inventoryStockHistories) {
      let inventoryStockHistoryEntity = await dbConnection
        .getRepository(InventoryStockHistory)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: inventoryStockHistory.id })
        .getOne();
      let insertMode = false;
      if (!inventoryStockHistoryEntity) {
        insertMode = true;
        inventoryStockHistoryEntity = new InventoryStockHistory();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && inventoryStockHistoryEntity.last_modified < inventoryStockHistory.last_modified)) {
        inventoryStockHistoryEntity.id = inventoryStockHistory.id;
        inventoryStockHistoryEntity.business_id = inventoryStockHistory.business_id;
        inventoryStockHistoryEntity.location_id = inventoryStockHistory.location_id;
        inventoryStockHistoryEntity.inventory_stock_id = inventoryStockHistory.inventory_stock_id;
        inventoryStockHistoryEntity.inventory_stock_adjustment_id = inventoryStockHistory.inventory_stock_adjustment_id;
        inventoryStockHistoryEntity.action = inventoryStockHistory.action;
        inventoryStockHistoryEntity.quantity = inventoryStockHistory.quantity;
        inventoryStockHistoryEntity.quantity_after = inventoryStockHistory.quantity_after;
        inventoryStockHistoryEntity.metadata = inventoryStockHistory.metadata ? JSON.stringify(inventoryStockHistory.metadata) : null;
        inventoryStockHistoryEntity.created_at = inventoryStockHistory.created_at;
        inventoryStockHistoryEntity.updated_at = inventoryStockHistory.updated_at;
        inventoryStockHistoryEntity.last_modified = inventoryStockHistory.last_modified;
        inventoryStockHistoryEntity.isSynced = true;
        await dbConnection.getRepository(InventoryStockHistory).save(inventoryStockHistoryEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedInventoryStockHistories = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const inventoryStockHistories = await dbConnection
    .getRepository(InventoryStockHistory)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return inventoryStockHistories.length > 0 ? inventoryStockHistories : null;
}