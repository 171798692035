import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { IncomeCategory } from '@/entity/IncomeCategory';

export const mergeIncomeCategories = async (businessId: any, locationId: any, incomeCategories: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching income categories data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (incomeCategories && incomeCategories.length > 0) {
    for (const incomeCategory of incomeCategories) {
      let incomeCategoryEntity = await dbConnection
        .getRepository(IncomeCategory)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: incomeCategory.id })
        .getOne();
      let insertMode = false;
      if (!incomeCategoryEntity) {
        insertMode = true;
        incomeCategoryEntity = new IncomeCategory();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && incomeCategoryEntity.last_modified < incomeCategory.last_modified)) {
        incomeCategoryEntity.id = incomeCategory.id;
        incomeCategoryEntity.business_id = incomeCategory.business_id;
        incomeCategoryEntity.location_id = incomeCategory.location_id;
        incomeCategoryEntity.name = incomeCategory.name;
        incomeCategoryEntity.description = incomeCategory.description;
        incomeCategoryEntity.is_enabled = incomeCategory.is_enabled;
        incomeCategoryEntity.created_at = incomeCategory.created_at;
        incomeCategoryEntity.updated_at = incomeCategory.updated_at;
        incomeCategoryEntity.last_modified = incomeCategory.last_modified;
        incomeCategoryEntity.isSynced = true;
        await dbConnection.getRepository(IncomeCategory).save(incomeCategoryEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedIncomeCategories = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const incomeCategories = await dbConnection
    .getRepository(IncomeCategory)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return incomeCategories.length > 0 ? incomeCategories : null;
}