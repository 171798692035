import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import config from '@/config';
import { getItem, setItem } from '@/utils/storage';
import { useLogOutAccount, useLogOutOperator } from '@/composables/useLogOut';
import { useAuthStore } from '@/stores/authStore';

// ====== Vue Loading Overlay
import { useLoading } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
const VueLoadingConfig = {
  canCancel: false, // default false
  // onCancel: this.yourCallbackMethod,
  color: 'var(--primary-color)',
  loader: 'dots', // spinner, dots, bars
  width: 64,
  height: 64,
  backgroundColor: '#ffffff',
  opacity: 0.2,
  // zIndex: 999,
}
const $loading = useLoading();
let loader: any = null;
const showLoader = () => {
  hideLoader();
  loader = $loading.show(VueLoadingConfig);
}
const hideLoader = () => {
  // destroy previous
  if (loader) {
    loader.hide();
    loader = null;
  }
}


//  ====== Routes
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  // {
  //   path: '/testprint',
  //   name: 'testprint',
  //   component: () => import('../views/TestPrint.vue')
  // },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/HomePage.vue'),
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/RegisterPage.vue'),
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginPage.vue'),
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/login-oneclick',
    name: 'login-oneclick',
    component: () => import('../views/LoginOneClick.vue'),
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue'),
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/choose-account',
    name: 'choose-account',
    component: () => import('../views/ChooseAccount.vue'),
    meta: {
      requiresBusinessAuth: true
    }
  },
  {
    path: '/initialize',
    name: 'initialize',
    component: () => import('../views/InitializeDatabase.vue'),
    meta: {
      requiresBusinessAuth: true
    }
  },
  {
    path: '/lockscreen',
    name: 'lockscreen',
    component: () => import('../views/LockScreen.vue'),
    meta: {
      requiresBusinessAuth: true
    },
  },
  {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
    path: "",
    component: () => import("../layouts/MainLayout.vue"),
    children: [
      {
        path: "", // no "/" in path of this route
        redirect: '/home'
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/DashboardPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
        }
      },
      {
        // User Settings Layout
        path: "/settings",
        component: () => import("../views/settings/SettingsLayout.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: 'any',
        },
        children: [
          {
            path: "", // no "/" in path of this route
            name: "settings",
            redirect: '/settings/profile'
          },
          {
            path: "profile",
            name: "settings-profile-settings",
            component: () => import("../views/settings/ProfileSettingsPage.vue"),
          },
          // {
          //   path: "account",
          //   name: "settings-account-settings",
          //   component: () => import("../views/settings/AccountSettingsPage.vue"),
          // },
          {
            path: "printers",
            name: "settings-printers-settings",
            component: () => import("../views/settings/PrintersSettings/PrintersSettingsPage.vue"),
          },
        ],
      }, // End of admin settings layout routes
      // ======================================
      // extension: customer
      {
        path: "/customers",
        name: "customers",
        component: () => import("../views/extensions/customer/CustomersPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['customer.view', 'customer.create', 'customer.edit', 'customer.delete'],
        }
      },
      // extension: reservations
      {
        path: "/reservations",
        name: "reservations",
        component: () => import("../views/extensions/reservation/ReservationsPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['reservation.view', 'reservation.create', 'reservation.edit', 'reservation.delete'],
        }
      },
      {
        path: "/reservations/new-booking",
        name: "reservations-new-booking",
        component: () => import("../views/extensions/reservation/ReservationNewBookingPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['reservation.create'],
        }
      },
      {
        path: "/reservations/new-booking-form/:categoryId",
        name: "reservations-new-booking-form",
        component: () => import("../views/extensions/reservation/ReservationNewBookingFormPage.vue"),
        props: true,
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['reservation.create'],
        }
      },
      {
        path: "/reservations/facilities",
        name: "reservations-facilities",
        component: () => import("../views/extensions/reservation/ReservationFacilitiesPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['reservation.facilities.view', 'reservation.facilities.create', 'reservation.facilities.edit', 'reservation.facilities.delete'],
        }
      },
      {
        path: "/reservations/categories",
        name: "reservations-categories",
        component: () => import("../views/extensions/reservation/ReservationCategoriesPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['reservation.categories.view', 'reservation.categories.create', 'reservation.categories.edit', 'reservation.categories.delete'],
        }
      },
      {
        path: "/reservations/house-keeping",
        name: "reservations-house-keeping",
        component: () => import("../views/extensions/reservation/ReservationHouseKeepingPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['reservation.house-keeping.view', 'reservation.house-keeping.edit'],
        }
      },
      // extension: income
      {
        path: "/income",
        name: "income",
        component: () => import("../views/extensions/income/IncomePage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['income.view', 'income.create', 'income.edit', 'income.delete'],
        }
      },
      {
        path: "/income/category",
        name: "income-category",
        component: () => import("../views/extensions/income/IncomeCategoryPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['income.categories.view', 'income.categories.create', 'income.categories.edit', 'income.categories.delete'],
        }
      },
      // extension: expense
      {
        path: "/expense",
        name: "expense",
        component: () => import("../views/extensions/expense/ExpensePage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['expense.view', 'expense.create', 'expense.edit', 'expense.delete'],
        }
      },
      {
        path: "/expense/category",
        name: "expense-category",
        component: () => import("../views/extensions/expense/ExpenseCategoryPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['expense.categories.view', 'expense.categories.create', 'expense.categories.edit', 'expense.categories.delete'],
        }
      },
      // extension: pool
      {
        path: "/pool",
        name: "pool",
        component: () => import("../views/extensions/pool/PoolPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['pool.view', 'pool.create', 'pool.edit', 'pool.delete'],
        }
      },
      {
        path: "/pool/catalogue",
        name: "pool-catalogue",
        component: () => import("../views/extensions/pool/PoolCataloguePage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['pool.catalogues.view', 'pool.catalogues.create', 'pool.catalogues.edit', 'pool.catalogues.delete'],
        }
      },
      // extension: laundry
      {
        path: "/laundry/orders",
        name: "laundry-orders",
        component: () => import("../views/extensions/laundry/LaundryOrdersPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['laundry.view', 'laundry.create', 'laundry.edit', 'laundry.delete'],
        }
      },
      {
        path: "/laundry/orders/form",
        name: "laundry-order-form",
        component: () => import("../views/extensions/laundry/LaundryOrderFormPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['laundry.create'],
        }
      },
      {
        path: "/laundry/service-list",
        name: "laundry-service-list",
        component: () => import("../views/extensions/laundry/LaundryServiceListPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['laundry.service-lists.view', 'laundry.service-lists.create', 'laundry.service-lists.edit', 'laundry.service-lists.delete'],
        }
      },
      // parking
      {
        path: "/parking/parking-ticket",
        name: "parking-ticket",
        component: () => import("../views/extensions/parking/ParkingTicketPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['parking-ticket.view', 'parking-ticket.create', 'parking-ticket.edit', 'parking-ticket.delete'],
        }
      },
      // extension: booking-order
      {
        path: "/booking-orders",
        name: "booking-orders",
        component: () => import("../views/extensions/booking-order/BookingOrdersPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['booking-order.view', 'booking-order.create', 'booking-order.edit', 'booking-order.delete'],
        }
      },
      {
        path: "/booking-orders/form",
        name: "booking-order-form",
        component: () => import("../views/extensions/booking-order/BookingOrderFormPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['booking-order.create'],
        }
      },
      // extension: sales
      {
        path: "/sales",
        name: "sales-orders",
        component: () => import("../views/extensions/sales/SalesOrdersPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['sales.view', 'sales.create', 'sales.edit', 'sales.delete'],
        }
      },
      {
        path: "/sales/orders/form",
        name: "sales-order-form",
        component: () => import("../views/extensions/sales/SalesOrderFormPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['sales.create'],
        }
      },
      // extension: inventory
      {
        path: "/inventory/items",
        name: "inventory-items",
        component: () => import("../views/extensions/inventory/InventoryItemsPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['inventory.items.view', 'inventory.items.create', 'inventory.items.edit', 'inventory.items.delete'],
        }
      },
      {
        path: "/inventory/category",
        name: "inventory-category",
        component: () => import("../views/extensions/inventory/InventoryCategoryPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['inventory.categories.view', 'inventory.categories.create', 'inventory.categories.edit', 'inventory.categories.delete'],
        }
      },
      {
        path: "/inventory/purchase-orders",
        name: "inventory-purchase-orders",
        component: () => import("../views/extensions/inventory/InventoryPurchaseOrdersPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['inventory.purchase-orders.view', 'inventory.purchase-orders.create', 'inventory.purchase-orders.edit', 'inventory.purchase-orders.delete'],
        }
      },
      {
        path: "/inventory/purchase-orders/form",
        name: "inventory-purchase-order-form",
        component: () => import("../views/extensions/inventory/InventoryPurchaseOrderFormPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['inventory.purchase-orders.create'],
        }
      },
      {
        path: "/inventory/stocks",
        name: "inventory-stocks",
        component: () => import("../views/extensions/inventory/InventoryStocksPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['inventory.items.view'],
        }
      },
      {
        path: "/inventory/stock-adjustments",
        name: "inventory-stock-adjustments",
        component: () => import("../views/extensions/inventory/InventoryStockAdjustmentsPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['inventory.stock-adjustments.manage'],
        }
      },
      {
        path: "/inventory/stock-adjustments/form",
        name: "inventory-stock-adjustment-form",
        component: () => import("../views/extensions/inventory/InventoryStockAdjustmentFormPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['inventory.stock-adjustments.manage'],
        }
      },
      {
        path: "/inventory/suppliers",
        name: "inventory-suppliers",
        component: () => import("../views/extensions/inventory/InventorySuppliersPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['inventory.suppliers.view', 'inventory.suppliers.create', 'inventory.suppliers.edit', 'inventory.suppliers.delete'],
        }
      },
      {
        path: "/inventory/locations",
        name: "inventory-locations",
        component: () => import("../views/extensions/inventory/InventoryLocationsPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['inventory.locations.view', 'inventory.locations.create', 'inventory.locations.edit', 'inventory.locations.delete'],
        }
      },

      // admin routes
      {
        path: "/admin/permissions",
        name: "admin-permissions",
        component: () => import("../views/admin/permissions/PermissionsPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['user-role::owner', 'role.view', 'role.create', 'role.edit', 'role.delete'],
        }
      },
      {
        path: "/admin/administrators",
        name: "admin-administrators",
        component: () => import("../views/admin/users/AdministratorsPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['user-role::owner',  'user.view', 'user.create', 'user.edit', 'user.delete'],
        }
      },
      {
        path: "/admin/operators",
        name: "admin-operators",
        component: () => import("../views/admin/users/OperatorsPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['user-role::owner', 'user-role::admin', 'user.view', 'user.create', 'user.edit', 'user.delete'],
        }
      },
      {
        path: "/admin/reports",
        name: "admin-reports",
        component: () => import("../views/admin/reports/ReportPage.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['user-role::owner', 'user-role::admin', 'report.view'],
        }
      },
      {
        // Admin Settings Layout
        path: "/admin/settings",
        component: () => import("../views/admin/settings/AdminSettingsLayout.vue"),
        meta: {
          requiresBusinessAuth: true,
          requiresOperatorAuth: true,
          anyCan: ['user-role::owner', 'user-role::admin'],
        },
        children: [
          {
            path: "", // no "/" in path of this route
            name: "admin-settings",
            redirect: '/admin/settings/profile'
          },
          {
            path: "profile",
            name: "admin-settings-profile-settings",
            component: () => import("../views/admin/settings/AdminProfileSettingsPage.vue"),
          },
          {
            path: "account",
            name: "admin-settings-account-settings",
            component: () => import("../views/admin/settings/AdminAccountSettingsPage.vue"),
          },
        ],
      }, // End of admin settings layout routes
    ] 
  }, // END OF MAIN LAYOUT ROUTES  =================================================
  // Unauthorized route
  {
    path: "/unauthorized",
    name: "unauthorized",
    component: () => import("../views/UnauthorizedPage.vue"),
    meta: {
      requiresBusinessAuth: true,
      // requiresOperatorAuth: true,
    }
  },
  // Error 404
  {
    path: '/:pathMatch(.*)*',
    name: 'error-404',
    component: () => import('../views/Error404Page.vue'),
    meta: {
      // requiresBusinessAuth: true,
      // requiresOperatorAuth: true,
    }
  }
]


//  ====== create the router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});



//  ====== Router event config
router.beforeEach(async (to, from, next) => {

  const authStore = useAuthStore();

  // Remove initial page loading if an
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }

  // If this isn't an initial page load.
  if (to.name) {
    showLoader(); // vue-loading-overlay
  }

  // authenticate business session
  const isBusinessAuthenticated = await authStore.authenticateBusinessSession();
  if (!isBusinessAuthenticated) {
    if (to.name !== 'login' && to.name !== 'choose-account' && to.name !== 'initialize' && (to.meta?.requiresBusinessAuth === true || to.meta?.requiresOperatorAuth === true)) {
      // try logout Account completely if any business misrepresentation
      await useLogOutAccount(false, false);
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    if (to.name === 'home') {
      next({ name: 'lockscreen' });

    } else if (to.name === 'login' || to.meta?.requiresGuest === true) {
      next({ name: 'home' });

    } else if (to.name === 'lockscreen') {
      const isAuthorized = await getItem(config.localStorageKeyNames.isAuthorized);
      if (!isAuthorized) {
        await  setItem(config.localStorageKeyNames.initDatabase, true);
        next({ name: 'initialize' });
      } else {
        next();
      }

    } else {
      const isOperatorAuthenticated = await authStore.authenticateOperatorSession();
      if (!isOperatorAuthenticated) {
        if (to.name !== 'lockscreen' && to.meta?.requiresOperatorAuth === true) {
          // try logout Operator completely if any misrepresentation
          await useLogOutOperator(false, false);
          next({ name: 'lockscreen' });
        } else {
          next();
        }
      } else {
        // PROCEED and allow the vue-simple-acl to handle the rest of the permissions.
        next();
      }
    }
  }
  
});



router.afterEach(() => {
  // any authenticate  session

  // vue-loading-overlay
  hideLoader();
});

router.onError(() => {
  // vue-loading-overlay
  // hideLoader();
});

export default router
