import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { PoolCatalogue } from '@/entity/PoolCatalogue';

export const mergePoolCatalogues = async (businessId: any, locationId: any, poolCatalogues: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching pool catalogues data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (poolCatalogues && poolCatalogues.length > 0) {
    for (const poolCatalogue of poolCatalogues) {
      let poolCatalogueEntity = await dbConnection
        .getRepository(PoolCatalogue)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: poolCatalogue.id })
        .getOne();
      let insertMode = false;
      if (!poolCatalogueEntity) {
        insertMode = true;
        poolCatalogueEntity = new PoolCatalogue();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && poolCatalogueEntity.last_modified < poolCatalogue.last_modified)) {
        poolCatalogueEntity.id = poolCatalogue.id;
        poolCatalogueEntity.business_id = poolCatalogue.business_id;
        poolCatalogueEntity.location_id = poolCatalogue.location_id;
        poolCatalogueEntity.name = poolCatalogue.name;
        poolCatalogueEntity.amount = poolCatalogue.amount;
        poolCatalogueEntity.description = poolCatalogue.description;
        poolCatalogueEntity.is_enabled = poolCatalogue.is_enabled;
        poolCatalogueEntity.created_at = poolCatalogue.created_at;
        poolCatalogueEntity.updated_at = poolCatalogue.updated_at;
        poolCatalogueEntity.last_modified = poolCatalogue.last_modified;
        poolCatalogueEntity.isSynced = true;
        await dbConnection.getRepository(PoolCatalogue).save(poolCatalogueEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedPoolCatalogues = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const poolCatalogues = await dbConnection
    .getRepository(PoolCatalogue)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return poolCatalogues.length > 0 ? poolCatalogues : null;
}