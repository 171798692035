const { version, buildNumber } = require('../package.json');

export default {
  appName: process.env.VUE_APP_NAME,
  appVersion: version,
  appBuildNumber: buildNumber,
  apiUrl: process.env.VUE_APP_API_URL,
  mercureServerUrl: process.env.VUE_APP_MERCURE_SERVER_URL,
  mercureSubscriberToken: process.env.VUE_APP_MERCURE_SUBSCRIBER_JWS,

  appEnv: process.env.NODE_ENV,
  isDev: process.env.NODE_ENV === 'development',
  isProd: process.env.NODE_ENV === 'production',
  logging: false, 

  localStorageKeyNames: {
    colorScheme: 'color_scheme',
    themeColor: 'theme_color',
    token: 'rerpapp_t',
    tokenExpireAt: 'rerpapp_t_exp_at',
    tokenExpireIn: 'rerpapp_t_exp_in',
    userId: 'rerpapp_u_i',
    businessId: 'rerpapp_bz_i',
    locationId: 'rerpapp_lo_i',
    currentOperatorId: 'rerpapp_op_i',
    initDatabase: 'rerpapp_i_db',
    chooseAccount: 'rerpapp_c_acc',
    isAuthorized: 'rerpapp_i_au',
    signedIn: 'rerpapp_i_si',
    lastKnownServerTime: 'rerpapp_l_k_s_tm', 
    lastActivityAt: 'rerpapp_l_ati', 
    lastSyncPulledAt: 'rerpapp_l_s_p_at',
    lastSyncPushedAt: 'rerpapp_l_s_ps_at',
    lastPullChangesExecutedAt: 'rerpapp_l_s_p_c_exec',
    lastPushChangesExecutedAt: 'rerpapp_l_s_ps_c_exec',
    syncPushTimer: 'rerpapp_syn_tmr',
    modifiedTables: 'rerpapp_m_tbl',
    queue: 'rerpapp_q',
    printer: 'rerpapp_prnt',
    printerType: 'rerpapp_prnt_typ',
    salesInventoryLocation: 'rerpapp_s_i_l',
    suspendedCarts: {
      salesOrders: 'rerpapp_scart_sales',
      bookingOrders: 'rerpapp_scart_booking_orders',
      laundryOrders: 'rerpapp_scart_laundry',
    }
  },
  tokenExpiryTimeLeftForFrontendAutoRefresh: 18000, // in seconds, 18000secs = 5 hours
  databaseSchemaVersion: 1,
  printDelay: 1000,

  getSubdomain: () => {
    const host: any = window.location.host;
    const parts: any = host.split(".");
    const subdomain: any = parts[0];
    return subdomain;
  }
}