import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm";

@Entity({ name: 'devices' })
@Index(['business_id', 'location_id', 'id'])
@Index(['business_id', 'location_id', 'last_modified'])
@Index(['business_id', 'location_id', 'isSynced'])
@Index(['business_id', 'location_id', 'type', 'connected_device_id', 'name'])
export class Device extends BaseEntity {
  @PrimaryColumn()
  id: string;

  @Column()
  @Index()
  business_id: string;

  @Column() 
  @Index()
  location_id: string;

  @Column({ nullable: true })
  @Index()
  operator_id: string;

  @Column() 
  type: string;

  @Column()
  connected_device_id: string;

  @Column({ nullable: true }) 
  name: string;

  @Column({ type: 'text', nullable: true })
  metadata: string;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;
}