import axios from "axios";
import config from "./config";
import { getItem, setItem } from "@/utils/storage";

const axiosInstance = axios.create({
  baseURL: config.apiUrl, // Set api base url from .env
  // timeout: 30000, // default is `0` millisecods (no timeout)
  headers: {
    Accept: "application/json",
    //"Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    // "X-Authorization": config.apiKey // Set api key from .env
  }
});
// Add access token to header if any
const accessToken = async () => await getItem(config.localStorageKeyNames.token);
if (accessToken) {
  axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
} else {
  axiosInstance.defaults.headers.common["Authorization"] = '';
  delete axiosInstance.defaults.headers.common["Authorization"];
}
axiosInstance.defaults.withCredentials = true;

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (axiosConfig: any) => {
    // Add access token to header before request is sent if any
    const accessToken = await getItem(config.localStorageKeyNames.token);
    if (accessToken) {
      axiosConfig.headers.Authorization = "Bearer " + accessToken;
    } else {
      axiosConfig.headers.Authorization = '';
      delete axiosConfig.headers.Authorization;
    }
    return axiosConfig;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  async function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    
    // save last know server timestamp
    if (response.data.timestamp) await setItem(config.localStorageKeyNames.lastKnownServerTime, response.data.timestamp);

    return response;
  },
  async function(error) {
    if (error.response) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger

      // save last know server timestamp
      if (error.response.data.timestamp) await setItem(config.localStorageKeyNames.lastKnownServerTime, error.response.data.timestamp);

      if (error.response.status === 401) {
        //place your re-entry code
        //app.$router.push('/login?expired=1');
      }
      //return Promise.reject(error);
      return Promise.reject(error.response);
    } else {
      return Promise.reject({
        status: 500,
        data: {
          status: 'error',
          message: "Something went wrong, please try again later."
        }
      });
    }
  }
);

export default axiosInstance;