import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { InventoryPurchaseOrder } from '@/entity/InventoryPurchaseOrder';

export const mergeInventoryPurchaseOrders = async (businessId: any, locationId: any, inventoryPurchaseOrders: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching inventory purchase orders data... Please wait.');
  
  const dbConnection: any = await getDBConnection();
  if (inventoryPurchaseOrders && inventoryPurchaseOrders.length > 0) {
    for (const inventoryPurchaseOrder of inventoryPurchaseOrders) {
      let inventoryPurchaseOrderEntity = await dbConnection
        .getRepository(InventoryPurchaseOrder)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: inventoryPurchaseOrder.id })
        .getOne();
      let insertMode = false;
      if (!inventoryPurchaseOrderEntity) {
        insertMode = true;
        inventoryPurchaseOrderEntity = new InventoryPurchaseOrder();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && inventoryPurchaseOrderEntity.last_modified < inventoryPurchaseOrder.last_modified)) {
        inventoryPurchaseOrderEntity.id = inventoryPurchaseOrder.id;
        inventoryPurchaseOrderEntity.business_id = inventoryPurchaseOrder.business_id;
        inventoryPurchaseOrderEntity.location_id = inventoryPurchaseOrder.location_id;
        inventoryPurchaseOrderEntity.operator_id = inventoryPurchaseOrder.operator_id;
        inventoryPurchaseOrderEntity.inventory_location_id = inventoryPurchaseOrder.inventory_location_id;
        inventoryPurchaseOrderEntity.inventory_supplier_id = inventoryPurchaseOrder.inventory_supplier_id;
        inventoryPurchaseOrderEntity.code = inventoryPurchaseOrder.code;
        inventoryPurchaseOrderEntity.subtotal = inventoryPurchaseOrder.subtotal;
        inventoryPurchaseOrderEntity.additional_cost = inventoryPurchaseOrder.additional_cost;
        inventoryPurchaseOrderEntity.total = inventoryPurchaseOrder.total;
        inventoryPurchaseOrderEntity.paid_amount = inventoryPurchaseOrder.paid_amount;
        inventoryPurchaseOrderEntity.payment_status = inventoryPurchaseOrder.payment_status;
        inventoryPurchaseOrderEntity.payment_method = inventoryPurchaseOrder.payment_method;
        inventoryPurchaseOrderEntity.notes = inventoryPurchaseOrder.notes;
        inventoryPurchaseOrderEntity.status = inventoryPurchaseOrder.status;
        inventoryPurchaseOrderEntity.purchased_at = inventoryPurchaseOrder.purchased_at;
        inventoryPurchaseOrderEntity.expected_at = inventoryPurchaseOrder.expected_at;
        inventoryPurchaseOrderEntity.received_at = inventoryPurchaseOrder.received_at;
        inventoryPurchaseOrderEntity.metadata = inventoryPurchaseOrder.metadata ? JSON.stringify(inventoryPurchaseOrder.metadata) : null;
        inventoryPurchaseOrderEntity.created_at = inventoryPurchaseOrder.created_at;
        inventoryPurchaseOrderEntity.updated_at = inventoryPurchaseOrder.updated_at;
        inventoryPurchaseOrderEntity.last_modified = inventoryPurchaseOrder.last_modified;
        inventoryPurchaseOrderEntity.isSynced = true;
        await dbConnection.getRepository(InventoryPurchaseOrder).save(inventoryPurchaseOrderEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedInventoryPurchaseOrders = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const inventoryPurchaseOrders = await dbConnection
    .getRepository(InventoryPurchaseOrder)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return inventoryPurchaseOrders.length > 0 ? inventoryPurchaseOrders : null;
}