import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { Customer } from '@/entity/Customer';

export const mergeCustomers = async (businessId: any, locationId: any, customers: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching customers data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (customers && customers.length > 0) {
    for (const customer of customers) {
      let customerEntity = await dbConnection
        .getRepository(Customer)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: customer.id })
        .getOne();
      let insertMode = false;
      if (!customerEntity) {
        insertMode = true;
        customerEntity = new Customer();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && customerEntity.last_modified < customer.last_modified)) {
        customerEntity.id = customer.id;
        customerEntity.business_id = customer.business_id;
        customerEntity.location_id = customer.location_id;
        customerEntity.title = customer.title;
        customerEntity.name = customer.name;
        customerEntity.email = customer.email;
        customerEntity.phone = customer.phone;
        customerEntity.gender = customer.gender;
        customerEntity.occupation = customer.occupation;
        customerEntity.address = customer.address;
        customerEntity.city = customer.city;
        customerEntity.state = customer.state;
        customerEntity.country = customer.country;
        customerEntity.nationality = customer.nationality;
        customerEntity.metadata = customer.metadata ? JSON.stringify(customer.metadata) : null;
        customerEntity.created_at = customer.created_at;
        customerEntity.updated_at = customer.updated_at;
        customerEntity.last_modified = customer.last_modified;
        customerEntity.isSynced = true;
        await dbConnection.getRepository(Customer).save(customerEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedCustomers = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const customers = await dbConnection
    .getRepository(Customer)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return customers.length > 0 ? customers : null;
}