import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { OperatorRole } from '@/entity/OperatorRole';

export const mergeOperatorRoles = async (businessId: any, locationId: any, operatorRoles: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching operator roles data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (operatorRoles && operatorRoles.length > 0) {
    for (const operatorRole of operatorRoles) {
      let operatorRoleEntity = await dbConnection
        .getRepository(OperatorRole)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: operatorRole.id })
        .getOne();
      let insertMode = false;
      if (!operatorRoleEntity) {
        insertMode = true;
        operatorRoleEntity = new OperatorRole();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && operatorRoleEntity.last_modified < operatorRole.last_modified)) {
        operatorRoleEntity.id = operatorRole.id;
        operatorRoleEntity.business_id = operatorRole.business_id;
        operatorRoleEntity.location_id = operatorRole.location_id;
        operatorRoleEntity.name = operatorRole.name;
        operatorRoleEntity.description = operatorRole.description;
        operatorRoleEntity.permissions = operatorRole.permissions ? JSON.stringify(operatorRole.permissions) : null;
        operatorRoleEntity.created_at = operatorRole.created_at;
        operatorRoleEntity.updated_at = operatorRole.updated_at;
        operatorRoleEntity.last_modified = operatorRole.last_modified;
        operatorRoleEntity.isSynced = true;
        await dbConnection.getRepository(OperatorRole).save(operatorRoleEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedOperatorRoles = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const operatorRoles = await dbConnection
    .getRepository(OperatorRole)
    .createQueryBuilder('operatorRole')
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return operatorRoles.length > 0 ? operatorRoles : null;
}