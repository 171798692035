// Sync service
import config from '@/config';
import axios from '@/axios';
import { getItem, setItem } from '@/utils/storage';
import { getAccessTokenDeviceCode } from '@/utils/device';
import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { useQueueStore } from '@/stores/queueStore';
import { getDBConnection, saveToWebStore } from '@/data-source';
import { DeletedRecord } from '@/entity/DeletedRecord';

import { mergeUser, getUnsyncedUser } from './user-sync';
import { mergeBusiness, getUnsyncedBusiness } from './business-sync';
import { mergeLocation, getUnsyncedLocation } from './location-sync';
// 
import { mergeOperatorRoles, getUnsyncedOperatorRoles } from './operator-roles-sync';
import { mergeOperators, getUnsyncedOperators } from './operators-sync';
import { mergeCustomers, getUnsyncedCustomers } from './customers-sync';
import { mergeIncomeCategories, getUnsyncedIncomeCategories } from './income-categories-sync';
import { mergeIncomes, getUnsyncedIncomes } from './incomes-sync';
import { mergeExpenseCategories, getUnsyncedExpenseCategories } from './expense-categories-sync';
import { mergeExpenses, getUnsyncedExpenses } from './expenses-sync';
import { mergeParkingTickets, getUnsyncedParkingTickets } from './parking-tickets-sync';
import { mergePoolCatalogues, getUnsyncedPoolCatalogues } from './pool-catalogues-sync';
import { mergePoolTickets, getUnsyncedPoolTickets } from './pool-tickets-sync';
import { mergeLaundryServiceLists, getUnsyncedLaundryServiceLists } from './laundry-service-lists-sync';
import { mergeLaundryOrders, getUnsyncedLaundryOrders } from './laundry-orders-sync';
import { mergeLaundryOrderItems, getUnsyncedLaundryOrderItems } from './laundry-order-items-sync';
import { mergeReservationCategories, getUnsyncedReservationCategories } from './reservation-categories-sync';
import { mergeReservationFacilities, getUnsyncedReservationFacilities } from './reservation-facilities-sync';
import { mergeReservations, getUnsyncedReservations } from './reservations-sync';
import { mergeInventoryCategories, getUnsyncedInventoryCategories } from './inventory-categories-sync';
import { mergeInventorySuppliers, getUnsyncedInventorySuppliers } from './inventory-suppliers-sync';
import { mergeInventoryLocations, getUnsyncedInventoryLocations } from './inventory-locations-sync';
import { mergeInventoryItems, getUnsyncedInventoryItems } from './inventory-items-sync';
import { mergeInventoryPurchaseOrders, getUnsyncedInventoryPurchaseOrders } from './inventory-purchase-orders-sync';
import { mergeInventoryPurchaseOrderItems, getUnsyncedInventoryPurchaseOrderItems } from './inventory-purchase-order-items-sync';
import { mergeInventoryStocks, getUnsyncedInventoryStocks } from './inventory-stocks-sync';
import { mergeInventoryStockHistories, getUnsyncedInventoryStockHistories } from './inventory-stock-histories-sync';
import { mergeInventoryStockAdjustments, getUnsyncedInventoryStockAdjustments } from './inventory-stock-adjustments-sync';
import { mergeInventoryStockAdjustmentItems, getUnsyncedInventoryStockAdjustmentItems } from './inventory-stock-adjustment-items-sync';
import { mergeSalesOrders, getUnsyncedSalesOrders } from './sales-orders-sync';
import { mergeSalesOrderItems, getUnsyncedSalesOrderItems } from './sales-order-items-sync';
import { mergeBookingOrders, getUnsyncedBookingOrders } from './booking-orders-sync';
import { mergeBookingOrderItems, getUnsyncedBookingOrderItems } from './booking-order-items-sync';
// 
import { mergeDevices, getUnsyncedDevices } from './devices-sync';
// import { mergeAudits, getUnsyncedAudits } from './audits-sync';
import { mergeDeletedRecords, getUnsyncedDeletedRecords } from './deleted-records-sync';

/**
 * Pull changes from server
 * @param fullSync 
 */
export const pullChanges = async (fullSync = false, onSuccessPush = false): Promise<void> => {
  const syncStatusStore = useSyncStatusStore();
  const queueStore = useQueueStore();
  try {
    const businessId = await getItem(config.localStorageKeyNames.businessId);
    const locationId = await getItem(config.localStorageKeyNames.locationId);

    if (!businessId || !locationId) {
      return;
    } else {

      const schemaVersion = config.databaseSchemaVersion;
      let lastSyncPulledAt = await getItem(config.localStorageKeyNames.lastSyncPulledAt);
      if (fullSync || !lastSyncPulledAt) {
        lastSyncPulledAt = 0;
      } 

      const isImmediateQueueProcessing = fullSync ? true : false;

      // before pull changes, get timestamp of pullChanges execution
      const lastPullChangesExecutedAt = Date.now();

      syncStatusStore.updateSyncPullProgressMessage('Downloading data from the server... Please wait.');
      
      const SYNC_API_URL = `/sync/${businessId}/${locationId}?lastSyncPulledAt=${lastSyncPulledAt}&schemaVersion=${schemaVersion}`;
      const response = await axios.get(SYNC_API_URL);
      const { data } = response;
      const { timestamp, changes } = data;
      
      const totalTaskCount = Object.keys(changes).length;
      if (changes && totalTaskCount > 0) {

        // start sync progress tracking
        syncStatusStore.startSyncPullTasks(totalTaskCount); 

        await queueStore.addToQueue(async () => {

          const {
            user,
            business,
            location,
            operator_roles,
            operators,
            customers,
            income_categories,
            incomes,
            expense_categories,
            expenses,
            parking_tickets,
            pool_catalogues,
            pool_tickets,
            laundry_service_lists,
            laundry_orders,
            laundry_order_items,
            reservation_categories,
            reservation_facilities,
            reservations,
            inventory_categories,
            inventory_suppliers,
            inventory_locations,
            inventory_items,
            inventory_purchase_orders,
            inventory_purchase_order_items,
            inventory_stocks,
            inventory_stock_histories,
            inventory_stock_adjustments,
            inventory_stock_adjustment_items,
            sales_orders,
            sales_order_items,
            booking_orders,
            booking_order_items,
            devices,
            // audits and deleted_records be defined last
            // audits,
            deleted_records,
          } = changes;
          if (user) await mergeUser(user);
          if (business) await mergeBusiness(business);
          if (location) await mergeLocation(businessId, location);
          // 
          if (operator_roles) await mergeOperatorRoles(businessId, locationId, operator_roles);
          if (operators) await mergeOperators(businessId, locationId, operators);
          if (customers) await mergeCustomers(businessId, locationId, customers);
          if (income_categories) await mergeIncomeCategories(businessId, locationId, income_categories);
          if (incomes) await mergeIncomes(businessId, locationId, incomes);
          if (expense_categories) await mergeExpenseCategories(businessId, locationId, expense_categories);
          if (expenses) await mergeExpenses(businessId, locationId, expenses);
          if (parking_tickets) await mergeParkingTickets(businessId, locationId, parking_tickets);
          if (pool_catalogues) await mergePoolCatalogues(businessId, locationId, pool_catalogues);
          if (pool_tickets) await mergePoolTickets(businessId, locationId, pool_tickets);
          if (laundry_service_lists) await mergeLaundryServiceLists(businessId, locationId, laundry_service_lists);
          if (laundry_orders) await mergeLaundryOrders(businessId, locationId, laundry_orders);
          if (laundry_order_items) await mergeLaundryOrderItems(businessId, locationId, laundry_order_items);
          if (reservation_categories) await mergeReservationCategories(businessId, locationId, reservation_categories);
          if (reservation_facilities) await mergeReservationFacilities(businessId, locationId, reservation_facilities);
          if (reservations) await mergeReservations(businessId, locationId, reservations);
          if (inventory_categories) await mergeInventoryCategories(businessId, locationId, inventory_categories);
          if (inventory_suppliers) await mergeInventorySuppliers(businessId, locationId, inventory_suppliers);
          if (inventory_locations) await mergeInventoryLocations(businessId, locationId, inventory_locations);
          if (inventory_items) await mergeInventoryItems(businessId, locationId, inventory_items);
          if (inventory_purchase_orders) await mergeInventoryPurchaseOrders(businessId, locationId, inventory_purchase_orders);
          if (inventory_purchase_order_items) await mergeInventoryPurchaseOrderItems(businessId, locationId, inventory_purchase_order_items);
          if (inventory_stocks) await mergeInventoryStocks(businessId, locationId, inventory_stocks);
          if (inventory_stock_histories) await mergeInventoryStockHistories(businessId, locationId, inventory_stock_histories);
          if (inventory_stock_adjustments) await mergeInventoryStockAdjustments(businessId, locationId, inventory_stock_adjustments);
          if (inventory_stock_adjustment_items) await mergeInventoryStockAdjustmentItems(businessId, locationId, inventory_stock_adjustment_items);
          if (sales_orders) await mergeSalesOrders(businessId, locationId, sales_orders);
          if (sales_order_items) await mergeSalesOrderItems(businessId, locationId, sales_order_items);
          if (booking_orders) await mergeBookingOrders(businessId, locationId, booking_orders);
          if (booking_order_items) await mergeBookingOrderItems(businessId, locationId, booking_order_items);
          // 
          if (devices) await mergeDevices(businessId, locationId, devices);
          // audits and deleted_records be defined last
          // if (audits) await mergeAudits(businessId, locationId, audits);
          if (deleted_records) await mergeDeletedRecords(businessId, locationId, deleted_records);

          // update last sync pulled at with new timestamp from server
          if (timestamp) {
            await setItem(config.localStorageKeyNames.lastSyncPulledAt, timestamp);
          }
          // update store to trigger re-render of current page data
          syncStatusStore.setSyncPulled(true);

        }, '', isImmediateQueueProcessing); // end of queueStore.addToQueue
      }

      // update pullChanges execution time
      await setItem(config.localStorageKeyNames.lastPullChangesExecutedAt, lastPullChangesExecutedAt);

      await queueStore.addToQueue(async () => {
        syncStatusStore.completeSyncPullProgress();
        if (onSuccessPush) {
          await pushChanges();
        }
      }, '', isImmediateQueueProcessing); // end of queueStore.addToQueue
    }
  } catch (error) {
    if (config.isDev && config.logging) console.error('pullChanges Error:', error);
    syncStatusStore.updateSyncPullProgressMessage('Error occurred while downloading data from the server. Please try again.');
  }
} // end pullChanges



/**
 * Push changes to server
 */
export const pushChanges = async (): Promise<void> => {
  const queueStore = useQueueStore();
  const syncStatusStore = useSyncStatusStore();
  try {
    const userId = await getItem(config.localStorageKeyNames.userId);
    const businessId = await getItem(config.localStorageKeyNames.businessId);
    const locationId = await getItem(config.localStorageKeyNames.locationId);
    if (!userId || !businessId || !locationId) {
      return;
    } else {

      // before pull changes, get timestamp of pushChanges execution
      const lastPushChangesExecutedAt = Date.now();

      await queueStore.addToQueue(async () => {

        const schemaVersion = config.databaseSchemaVersion;
        const lastSyncPulledAt = await getItem(config.localStorageKeyNames.lastSyncPulledAt);
        if (!lastSyncPulledAt) {
          // To ensure data integrity and avoid possible conflicts, we need to push changes to server only after the first pull is sync
          return;
        }

        const wideLookup = syncStatusStore.modifiedTables.length === 0 ? true : false;

        // Proceed since all condition checking is okay
        const changes: any = {
          user: wideLookup || syncStatusStore.isModifiedTable('user') ? await getUnsyncedUser(userId) : null,
          business: wideLookup || syncStatusStore.isModifiedTable('business') ? await getUnsyncedBusiness(businessId) : null,
          location: wideLookup || syncStatusStore.isModifiedTable('location') ? await getUnsyncedLocation(businessId, locationId) : null,
          // 
          operator_roles: wideLookup || syncStatusStore.isModifiedTable('operator_roles') ? await getUnsyncedOperatorRoles(businessId, locationId) : null,
          operators: wideLookup || syncStatusStore.isModifiedTable('operators') ? await getUnsyncedOperators(businessId, locationId) : null,
          customers: wideLookup || syncStatusStore.isModifiedTable('customers') ? await getUnsyncedCustomers(businessId, locationId) : null,
          income_categories: wideLookup || syncStatusStore.isModifiedTable('income_categories') ? await getUnsyncedIncomeCategories(businessId, locationId) : null,
          incomes: wideLookup || syncStatusStore.isModifiedTable('incomes') ? await getUnsyncedIncomes(businessId, locationId) : null,
          expense_categories: wideLookup || syncStatusStore.isModifiedTable('expense_categories') ? await getUnsyncedExpenseCategories(businessId, locationId) : null,
          expenses: wideLookup || syncStatusStore.isModifiedTable('expenses') ? await getUnsyncedExpenses(businessId, locationId) : null,
          parking_tickets: wideLookup || syncStatusStore.isModifiedTable('parking_tickets') ? await getUnsyncedParkingTickets(businessId, locationId) : null,
          pool_catalogues: wideLookup || syncStatusStore.isModifiedTable('pool_catalogues') ? await getUnsyncedPoolCatalogues(businessId, locationId) : null,
          pool_tickets: wideLookup || syncStatusStore.isModifiedTable('pool_tickets') ? await getUnsyncedPoolTickets(businessId, locationId) : null,
          laundry_service_lists: wideLookup || syncStatusStore.isModifiedTable('laundry_service_lists') ? await getUnsyncedLaundryServiceLists(businessId, locationId) : null,
          laundry_orders: wideLookup || syncStatusStore.isModifiedTable('laundry_orders') ? await getUnsyncedLaundryOrders(businessId, locationId) : null,
          laundry_order_items: wideLookup || syncStatusStore.isModifiedTable('laundry_order_items') ? await getUnsyncedLaundryOrderItems(businessId, locationId) : null,
          reservation_categories: wideLookup || syncStatusStore.isModifiedTable('reservation_categories') ? await getUnsyncedReservationCategories(businessId, locationId) : null,
          reservation_facilities: wideLookup || syncStatusStore.isModifiedTable('reservation_facilities') ? await getUnsyncedReservationFacilities(businessId, locationId) : null,
          reservations: wideLookup || syncStatusStore.isModifiedTable('reservations') ? await getUnsyncedReservations(businessId, locationId) : null,
          inventory_categories: wideLookup || syncStatusStore.isModifiedTable('inventory_categories') ? await getUnsyncedInventoryCategories(businessId, locationId) : null,
          inventory_suppliers: wideLookup || syncStatusStore.isModifiedTable('inventory_suppliers') ? await getUnsyncedInventorySuppliers(businessId, locationId) : null,
          inventory_locations: wideLookup || syncStatusStore.isModifiedTable('inventory_locations') ? await getUnsyncedInventoryLocations(businessId, locationId) : null,
          inventory_items: wideLookup || syncStatusStore.isModifiedTable('inventory_items') ? await getUnsyncedInventoryItems(businessId, locationId) : null,
          inventory_purchase_orders: wideLookup || syncStatusStore.isModifiedTable('inventory_purchase_orders') ? await getUnsyncedInventoryPurchaseOrders(businessId, locationId) : null,
          inventory_purchase_order_items: wideLookup || syncStatusStore.isModifiedTable('inventory_purchase_order_items') ? await getUnsyncedInventoryPurchaseOrderItems(businessId, locationId) : null,
          inventory_stocks: wideLookup || syncStatusStore.isModifiedTable('inventory_stocks') ? await getUnsyncedInventoryStocks(businessId, locationId) : null,
          inventory_stock_histories: wideLookup || syncStatusStore.isModifiedTable('inventory_stock_histories') ? await getUnsyncedInventoryStockHistories(businessId, locationId) : null,
          inventory_stock_adjustments: wideLookup || syncStatusStore.isModifiedTable('inventory_stock_adjustments') ? await getUnsyncedInventoryStockAdjustments(businessId, locationId) : null,
          inventory_stock_adjustment_items: wideLookup || syncStatusStore.isModifiedTable('inventory_stock_adjustment_items') ? await getUnsyncedInventoryStockAdjustmentItems(businessId, locationId) : null,
          sales_orders: wideLookup || syncStatusStore.isModifiedTable('sales_orders') ? await getUnsyncedSalesOrders(businessId, locationId) : null,
          sales_order_items: wideLookup || syncStatusStore.isModifiedTable('sales_order_items') ? await getUnsyncedSalesOrderItems(businessId, locationId) : null,
          booking_orders: wideLookup || syncStatusStore.isModifiedTable('booking_orders') ? await getUnsyncedBookingOrders(businessId, locationId) : null,
          booking_order_items: wideLookup || syncStatusStore.isModifiedTable('booking_order_items') ? await getUnsyncedBookingOrderItems(businessId, locationId) : null,
          // 
          devices: wideLookup || syncStatusStore.isModifiedTable('devices') ? await getUnsyncedDevices(businessId, locationId) : null,
          // audits and deleted_records be defined last
          // audits: wideLookup || syncStatusStore.isModifiedTable('audits') ? await getUnsyncedAudits(businessId, locationId) : null,
          deleted_records: wideLookup || syncStatusStore.isModifiedTable('deleted_records') ? await getUnsyncedDeletedRecords(businessId, locationId) : null,
        };
        // remove changes keys with empty values
        Object.keys(changes).forEach((key: any) => {
          if (!changes[key]) {
            delete changes[key];
          }
        });
        
        if (Object.keys(changes).length > 0) {

          const SYNC_API_URL = `/sync/${businessId}/${locationId}?lastSyncPulledAt=${lastSyncPulledAt}&schemaVersion=${schemaVersion}`;
          const postData = {
            changes: changes,
            device: await getAccessTokenDeviceCode()
          }
          await axios.post(SYNC_API_URL, postData)
          .then(async (response) => {
            // After successful push
            if (response.data.status === 'success') {
              // update lastSyncPushedAt with the latest timestamp from server
              if (response.data.timestamp) {
                await setItem(config.localStorageKeyNames.lastSyncPushedAt, response.data.timestamp);
              }

              const dbConnection: any = await getDBConnection();

              // Mark all pushed records as synced
              if (Object.keys(changes).length > 0) {
                Object.keys(changes).forEach(async (key: any) => {                  
                  const entities = changes[key] ?? [];                  
                  let tableName = key;
                  if (key === 'user') tableName = 'users';
                  if (key === 'business') tableName = 'businesses';
                  if (key === 'location') tableName = 'locations';
                  if (['user', 'business', 'location'].includes(key)) {
                    const [sql, params] = await dbConnection
                      .createQueryBuilder()
                      .update(tableName)
                      .set({ isSynced: true })
                      .where('LOWER(id) = LOWER(:id)', { id: entities.id })
                      .getQueryAndParameters();
                    await dbConnection.query(sql, params);
                  } else {
                    if (entities && entities.length > 0) {
                      const entityIds = entities.map((entity: any) => entity.id?.toString().toLowerCase());
                      const [sql, params] = await dbConnection
                        .createQueryBuilder()
                        .update(tableName)
                        .set({ isSynced: true })
                        .where('LOWER(id) IN (:...ids)', { ids: entityIds })                        
                        .getQueryAndParameters();
                      await dbConnection.query(sql, params); 
                    }
                  }
                  // remove modifiedTable list from syncStatusStore
                  syncStatusStore.removeModifiedTable(key);
                });
              } // end Mark all pushed records as synced
               

              // Permanently delete the deleted record rows, INTENTIONALLY used RAW SQL QUERY here instead of QueryBuilder to avoid broadcasting the event
              if (changes.deleted_records && changes.deleted_records.length > 0) {
                const deletedRecordsIds =  changes.deleted_records.map((record: any) => record.id?.toString().toLowerCase());
                const deletedRecordMetadata = dbConnection.getMetadata(DeletedRecord);
                const deletedRecordTableName = deletedRecordMetadata.tableName;
                await dbConnection
                  .query(`DELETE FROM ${deletedRecordTableName}
                            WHERE LOWER(business_id) = LOWER(:businessId)
                              AND LOWER(location_id) = LOWER(:locationId)
                              AND LOWER(id) IN (:ids)`, {
                    replacements: {
                      businessId: businessId,
                      locationId: locationId,
                      ids: deletedRecordsIds
                    }
                  });
              } // end permanently delete the deleted record rows

              // Raw Query? For WEB platform, this is to save the database to web store to prevent loss of data
              saveToWebStore();
            }
          })
          .catch(async (error) => {
            if (config.isDev && config.logging) console.error('pushChanges Error:', error);
            // error or conflict occurred due to un-synchronized changes, pull change and  re-pushing again
            await pullChanges(false, true);
          });
        }

      }, 'pushchanges'); // end of queueStore.addToQueue

      // update pullChanges execution time
      await setItem(config.localStorageKeyNames.lastPushChangesExecutedAt, lastPushChangesExecutedAt);
    }
  } catch (error) {
    if (config.isDev && config.logging) console.error('before queue pushChanges Error:', error);
  }
} // end pushChanges