import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm";
@Entity({ name: 'businesses' }) 
@Index(['id', 'last_modified'])
@Index(['id', 'isSynced'])
export class Business extends BaseEntity {
  @PrimaryColumn()
  id: string;

  @Column({ nullable: true }) 
  reseller_id: string;

  @Column() 
  name: string;

  @Column({ nullable: true })
  @Index()
  email: string;

  @Column({ nullable: true }) 
  phone: string;

  @Column({ type: 'text', nullable: true })
  address: string;

  @Column({ nullable: true }) 
  city: string;

  @Column({ nullable: true }) 
  state: string;

  @Column({ nullable: true }) 
  country: string;

  @Column({ default: 'active' })
  status: string;

  @Column({ type: 'text', nullable: true })
  photo: string;

  @Column()
  currency_code: string;

  @Column({ type: 'text', nullable: true })
  metadata: string;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;
}