import { defineStore, acceptHMRUpdate } from 'pinia';
import config from '@/config';
import { getItem } from '@/utils/storage';
import { getDBConnection } from '@/data-source';
import { User } from '@/entity/User';
import { Business } from '@/entity/Business';
import { Location } from '@/entity/Location';
import { Operator } from '@/entity/Operator';
import { OperatorRole } from '@/entity/OperatorRole';
import { userRoleList } from '@/dataset';

interface State {
  user: Array<any> | null;
  business: Array<any> | null;
  location: Array<any> | null;
  operators: Array<any>;
  operator: Array<any> | null;
  operatorSignedIn: boolean;
  operatorPermissions: Array<any>;
}

export const useAuthStore = defineStore('auth', {

  state: (): State => ({ 
    user: null,
    business: null,
    location: null,
    operators: [],
    operator: null,
    operatorSignedIn: false,
    operatorPermissions: [],
  }),

  getters: {
    userId: (state: any) => state.user?.id,
    businessId: (state: any) => state.business?.id,
    locationId: (state: any) => state.location?.id,
    operatorId: (state: any) => state.operator?.id,
  },

  actions: {
    setUser(user: any) {
      this.user = user;
    },
    setBusiness(business: any) {
      this.business = business;
    },
    setLocation(location: any) {
      this.location = location;
    },  
    setOperators(operators: any) {
      this.operators = operators;
    },
    setOperator(operator: any) {
      this.operator = operator;
    },    
    setOperatorSignedIn(status: boolean) {
      this.operatorSignedIn = status
    },
    setOperatorPermissions(permissions: any) {
      this.operatorPermissions = permissions;
    },

    async authenticateBusinessSession() {
      try {
        const userId = await getItem(config.localStorageKeyNames.userId);
        const businessId = await getItem(config.localStorageKeyNames.businessId);
        const locationId = await getItem(config.localStorageKeyNames.locationId);
        if (businessId && locationId && userId) {
          const dbConnection: any = await getDBConnection();
          const user = await dbConnection
            .getRepository(User)
            .createQueryBuilder('user')
            .where('user.id = :id', { id: userId })
            .getOne();
          if (!user) {
            return false;
          }
          this.setUser(user);
          const business = await dbConnection
            .getRepository(Business)
            .createQueryBuilder('business')
            .where('business.id = :businessId', { businessId })
            .getOne();
          if (business) {
            this.setBusiness(business);
  
            const location = await dbConnection
              .getRepository(Location)
              .createQueryBuilder('location')
              .where('location.business_id = :businessId', { businessId })
              .andWhere('location.id = :locationId', { locationId })
              .getOne();
            if (location) {
              this.setLocation(location);
            }
            return true;
          }
        }
        return false;
      } catch (error) {        
        return false;
      }
    },
  
    async authenticateOperatorSession() {
      try {
        const businessId = await getItem(config.localStorageKeyNames.businessId);
        const locationId = await getItem(config.localStorageKeyNames.locationId);
        const operatorId = await getItem(config.localStorageKeyNames.currentOperatorId);
        const signedIn = await getItem(config.localStorageKeyNames.signedIn);
        if (businessId && locationId && operatorId && signedIn) {
          const dbConnection: any = await getDBConnection();
          const operator = await dbConnection
            .getRepository(Operator)
            .createQueryBuilder('operator')
            .where('operator.business_id = :businessId', { businessId })
            .andWhere('operator.location_id = :locationId', { locationId })
            .andWhere('operator.id = :operatorId', { operatorId })
            .getOne();
          if (operator) {
            if (operator.status !== 'active') {
              return false;
            }
            this.setOperator(operator);
            // get operators permissions
            let permissions: any = [];
            if (operator.operator_role_id) {
              const role = await dbConnection
                .getRepository(OperatorRole)
                .createQueryBuilder('role')
                .where('role.business_id = :businessId', { businessId })
                .andWhere('role.location_id = :locationId', { locationId })
                .andWhere('role.id = :id', { id: operator.operator_role_id })
                .getOne();
              if (role && role.permissions) {
                const rolePermissions = JSON.parse(role.permissions);
                if (rolePermissions.length > 0) {
                  permissions = permissions.concat(rolePermissions);
                }
                operator.role_name = role.name;
                this.setOperator(operator);
              }
            }
            // if the operator is the authorizing user for the business, add the user's business level role to the permission list
            const user: any = this.user;
            if (operator.is_user && operator.username === user?.id && user?.role) {
              permissions.splice(0, 0, `user-role::${user.role}`); // user-role::admin
              if (!operator.name) {
                operator.name = user.name;
                operator.email = user.email;
                operator.phone = user.phone;
                operator.photo = user.photo;
                const dbConnection2: any = await getDBConnection(); // use new connection
                await dbConnection2.getRepository(Operator).save(operator);
              }
              operator.role_name = userRoleList.find(role => role.name === user.role)?.label ?? user.role;
              this.setOperator(operator);
            }
            this.setOperatorPermissions(permissions);
            return true;
          }
          return false;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
  }
});

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useAuthStore, import.meta.webpackHot));
}