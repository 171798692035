import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { LaundryServiceList } from '@/entity/LaundryServiceList';

export const mergeLaundryServiceLists = async (businessId: any, locationId: any, laundryServiceLists: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching laundry service lists data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (laundryServiceLists && laundryServiceLists.length > 0) {
    for (const laundryServiceList of laundryServiceLists) {
      let laundryServiceListEntity = await dbConnection
        .getRepository(LaundryServiceList)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: laundryServiceList.id })
        .getOne();
      let insertMode = false;
      if (!laundryServiceListEntity) {
        insertMode = true;
        laundryServiceListEntity = new LaundryServiceList();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && laundryServiceListEntity.last_modified < laundryServiceList.last_modified)) {
        laundryServiceListEntity.id = laundryServiceList.id;
        laundryServiceListEntity.business_id = laundryServiceList.business_id;
        laundryServiceListEntity.location_id = laundryServiceList.location_id;
        laundryServiceListEntity.name = laundryServiceList.name;
        laundryServiceListEntity.amount = laundryServiceList.amount;
        laundryServiceListEntity.description = laundryServiceList.description;
        laundryServiceListEntity.is_enabled = laundryServiceList.is_enabled;
        laundryServiceListEntity.created_at = laundryServiceList.created_at;
        laundryServiceListEntity.updated_at = laundryServiceList.updated_at;
        laundryServiceListEntity.last_modified = laundryServiceList.last_modified;
        laundryServiceListEntity.isSynced = true;
        await dbConnection.getRepository(LaundryServiceList).save(laundryServiceListEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedLaundryServiceLists = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const laundryServiceLists = await dbConnection
    .getRepository(LaundryServiceList)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return laundryServiceLists.length > 0 ? laundryServiceLists : null;
}