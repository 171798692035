import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { InventoryStock } from '@/entity/InventoryStock';

export const mergeInventoryStocks = async (businessId: any, locationId: any, inventoryStocks: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching inventory stocks data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (inventoryStocks && inventoryStocks.length > 0) {
    for (const inventoryStock of inventoryStocks) {
      let inventoryStockEntity = await dbConnection
        .getRepository(InventoryStock)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: inventoryStock.id })
        .getOne();
      let insertMode = false;
      if (!inventoryStockEntity) {
        insertMode = true;
        inventoryStockEntity = new InventoryStock();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && inventoryStockEntity.last_modified < inventoryStock.last_modified)) {
        inventoryStockEntity.id = inventoryStock.id;
        inventoryStockEntity.business_id = inventoryStock.business_id;
        inventoryStockEntity.location_id = inventoryStock.location_id;
        inventoryStockEntity.inventory_location_id = inventoryStock.inventory_location_id;
        inventoryStockEntity.inventory_item_id = inventoryStock.inventory_item_id;
        inventoryStockEntity.quantity = inventoryStock.quantity;
        inventoryStockEntity.metadata = inventoryStock.metadata ? JSON.stringify(inventoryStock.metadata) : null;
        inventoryStockEntity.created_at = inventoryStock.created_at;
        inventoryStockEntity.updated_at = inventoryStock.updated_at;
        inventoryStockEntity.last_modified = inventoryStock.last_modified;
        inventoryStockEntity.isSynced = true;
        await dbConnection.getRepository(InventoryStock).save(inventoryStockEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedInventoryStocks = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const inventoryStocks = await dbConnection
    .getRepository(InventoryStock)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return inventoryStocks.length > 0 ? inventoryStocks : null;
}