import { Storage } from '@capacitor/storage';

export async function setItem(key: string, value: any): Promise<void> {
  await Storage.set({
    key: key,
    value: JSON.stringify(value),
  });
}

export async function getItem(key: string): Promise<any> {
  const { value } = await Storage.get({ key: key });
  return value ? JSON.parse(value) : null;
}

export async function removeItem(key: string): Promise<void> {
  await Storage.remove({ key: key });
}

export async function getAllItemKeys(): Promise<any> {
  const { keys } = await Storage.keys();
  return keys
}