
import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm";

@Entity({ name: 'reservation_categories' }) 
@Index(['business_id', 'location_id', 'id'])
@Index(['business_id', 'location_id', 'last_modified'])
@Index(['business_id', 'location_id', 'isSynced'])
export class ReservationCategory extends BaseEntity {
  @PrimaryColumn()
  id: string;

  @Column()
  @Index()
  business_id: string;

  @Column() 
  @Index()
  location_id: string;

  @Column() 
  name: string;

  @Column({ type: 'decimal', precision: 26, scale: 8, default: 0.0 })
  amount: string;

  @Column({ nullable: true }) 
  amount_suffix: string;

  @Column({ type: 'text', nullable: true })
  other_amounts: string;

  @Column() 
  type: string;

  @Column({ type: 'text', nullable: true })
  description: string;

  @Column({ type: 'text', nullable: true })
  photo: string;

  @Column() 
  is_enabled: boolean;

  @Column({ type: 'text', nullable: true })
  metadata: string;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;
}