import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { InventoryItem } from '@/entity/InventoryItem';

export const mergeInventoryItems = async (businessId: any, locationId: any, inventoryItems: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching inventory items data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (inventoryItems && inventoryItems.length > 0) {
    for (const inventoryItem of inventoryItems) {
      let inventoryItemEntity = await dbConnection
        .getRepository(InventoryItem)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: inventoryItem.id })
        .getOne();
      let insertMode = false;
      if (!inventoryItemEntity) {
        insertMode = true;
        inventoryItemEntity = new InventoryItem();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && inventoryItemEntity.last_modified < inventoryItem.last_modified)) {
        inventoryItemEntity.id = inventoryItem.id;
        inventoryItemEntity.business_id = inventoryItem.business_id;
        inventoryItemEntity.location_id = inventoryItem.location_id;
        inventoryItemEntity.inventory_category_id = inventoryItem.inventory_category_id;
        inventoryItemEntity.inventory_supplier_id = inventoryItem.inventory_supplier_id;
        inventoryItemEntity.sku = inventoryItem.sku;
        inventoryItemEntity.barcode = inventoryItem.barcode;
        inventoryItemEntity.name = inventoryItem.name;
        inventoryItemEntity.description = inventoryItem.description;
        inventoryItemEntity.for_sale = inventoryItem.for_sale;
        inventoryItemEntity.price_by = inventoryItem.price_by;
        inventoryItemEntity.price = inventoryItem.price;
        inventoryItemEntity.sale_price = inventoryItem.sale_price;
        inventoryItemEntity.cost_price = inventoryItem.cost_price;
        inventoryItemEntity.track_stock = inventoryItem.track_stock;
        inventoryItemEntity.low_stock_quantity = inventoryItem.low_stock_quantity;
        inventoryItemEntity.optimal_order_quantity = inventoryItem.optimal_order_quantity;
        inventoryItemEntity.production_date = inventoryItem.production_date;
        inventoryItemEntity.expiry_date = inventoryItem.expiry_date;
        inventoryItemEntity.photo = inventoryItem.photo;
        inventoryItemEntity.is_enabled = inventoryItem.is_enabled;
        inventoryItemEntity.metadata = inventoryItem.metadata ? JSON.stringify(inventoryItem.metadata) : null;
        inventoryItemEntity.created_at = inventoryItem.created_at;
        inventoryItemEntity.updated_at = inventoryItem.updated_at;
        inventoryItemEntity.last_modified = inventoryItem.last_modified;
        inventoryItemEntity.isSynced = true;
        await dbConnection.getRepository(InventoryItem).save(inventoryItemEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedInventoryItems = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const inventoryItems = await dbConnection
    .getRepository(InventoryItem)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return inventoryItems.length > 0 ? inventoryItems : null;
}