import i18nISOCountries from 'i18n-iso-countries';

/**
 * User Roles
 */
export const userRoleList = [
  {
    name: 'owner',
    label: 'Owner',
    description: 'This is best for the business owners and executives.',
  },
  {
    name: 'admin',
    label: 'Admin',
    description: 'This is best for the business managers and executives.',
  },
  // {
  //   name: 'operations',
  //   label: 'Operations',
  //   description: 'This is best for the business department heads and executives.',
  // },
  // {
  //   name: 'customer-support',
  //   label: 'Customer Support',
  //   description: 'This is best for staff that perform actions like refunds, disputes managements.',
  // },
  // {
  //   name: 'sales',
  //   label: 'Sales',
  //   description: 'This is best for the business sales and executives.',
  // },
  // {
  //   name: 'marketing',
  //   label: 'Marketing',
  //   description: 'This is best for the business marketing and executives.',
  // },
  // {
  //   name: 'finance',
  //   label: 'Finance',
  //   description: 'This is best for the business finance and executives.',
  // },
  // {
  //   name: 'developer',
  //   label: 'Developer/IT Specialist',
  //   description: 'This is best for developers working with the APIs.',
  // },
  {
    name: 'user',
    label: 'User (Device Authorizer)',
    description: 'This is best for staff without the need to update core data. Basically, this role can only authorized/sign in a device and create/manage operators.',
  },
];

/**
 * Titles
 */
export const titleList = [
  { name: 'mr', label: 'Mr' },
  { name: 'mrs', label: 'Mrs' },
  { name: 'miss', label: 'Miss' },
  { name: 'ms', label: 'Ms' },
  { name: 'dr', label: 'Dr' },
  { name: 'prof', label: 'Prof' },
  { name: 'rev', label: 'Rev' },
  { name: 'sir', label: 'Sir' },
  { name: 'alhaji', label: 'Alhaji' },
];

/**
 * Gender
 */
export const genderList = [
  { name: 'male', label: 'Male' },
  { name: 'female', label: 'Female' },
];

/**
 * Colors
 */
export const colorList = [
  { name: 'black', label: 'Black' },
  { name: 'blue', label: 'Blue' },
  { name: 'gray', label: 'Gray' },
  { name: 'green', label: 'Green' }, 
  { name: 'red', label: 'Red' },
  { name: 'white', label: 'White' },
  { name: 'yellow', label: 'Yellow' },
  { name: 'custom', label: 'Custom' },
];

/**
 * Checkout Payment Method
 */
 export const checkoutPaymentMethodList = [
  { name: 'cash', label: 'Cash' },
  { name: 'card', label: 'Debit/Credit Card (POS)' },
  { name: 'bank_transfer', label: 'Bank Transfer' },
  // { name: 'online', label: 'Online Payment' },
];

/**
 * Payment Status
 */
 export const paymentStatusList = [
  { name: 'unpaid', label: 'Unpaid' },
  { name: 'partial_payment', label: 'Partial Payment' },
  { name: 'paid', label: 'Paid' },
];

/**
 * Order status
 */
 export const orderStatusList = [
   { name: 'cancelled', label: 'Cancelled' },
  { name: 'pending', label: 'Pending' },
  { name: 'processing', label: 'Processing' },
  { name: 'processed', label: 'Processed' },
  { name: 'ready_to_deliver', label: 'Ready To Deliver' },
  { name: 'delivered', label: 'Delivered' },
  { name: 'returned', label: 'Returned' },
];

/**
 * Stock order status
 */
 export const stockOrderStatusList = [
  { name: 'cancelled', label: 'Cancelled' },
  { name: 'pending', label: 'Pending' },
  { name: 'processing', label: 'Processing' },
  { name: 'processed', label: 'Processed' },
  { name: 'received', label: 'Received' },
  { name: 'partial_received', label: 'Partial Received' },
  { name: 'returned', label: 'Returned' },
  { name: 'partial_returned', label: 'Partial Returned' },
];

/**
 * Stock order status
 */
 export const stockAdjustmentTypeList = [
  { name: 'receive_items', label: 'Receive Items' },
  // { name: 'inventory_count', label: 'Inventory Count' },
  // { name: 'loss', label: 'Loss' },
  // { name: 'damaged', label: 'Damaged' },
  // { name: 'returned', label: 'Returned' },
];

/**
 * Reservation Category Type
 */
 export const reservationCategoryTypeList = [
  { name: 'conference_room', label: 'Conference Room' },
  { name: 'dining', label: 'Dinning' },
  { name: 'hall', label: 'Hall' },
  { name: 'room', label: 'Room' },
  { name: 'open_field', label: 'Open Field' },
];

/**
 * Reservation Status
 */
 export const reservationStatusList = [
  { name: 'cancelled', label: 'Cancelled' },
  { name: 'pending', label: 'Pending' },
  { name: 'processed', label: 'Processed' },
  { name: 'in', label: 'IN' },
  { name: 'out', label: 'OUT' },
];

/**
 * Reservation Facility Status
 */
 export const reservationFacilityStatusList = [
  { name: 'available', label: 'Available' },
  { name: 'busy', label: 'Busy' },
  { name: 'dirty', label: 'Dirty' },
  { name: 'cleaning', label: 'Cleaning' },
];

/**
 * Reservation Amount Suffix
 */
 export const reservationAmountSuffixList = [
  // { name: 'per_hour', label: 'Per Hour' },
  { name: 'per_night', label: 'Per Night' },
  { name: 'per_day', label: 'Per Day' },
  // { name: 'per_week', label: 'Per Week' },
];

/**
 * Device Type
 */
 export const deviceTypeList = [
  { name: 'terminal', label: 'Terminal' },
  { name: 'printer', label: 'Printer' },
  { name: 'customer_display_screen', label: 'Customer Display Screen' },
];

/**
 * Printer Type
 */
 export const printerConnectionTypeList = [
  { name: 'bluetooth', label: 'Bluetooth' },
  { name: 'builtin', label: 'Built-In' },
  { name: 'ethernet', label: 'Ethernet' },
  { name: 'usb', label: 'USB' },
  { name: 'wifi', label: 'WiFi' },
];

/**
 * countryList
 */
export const countryList: any = [];
i18nISOCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const isoC: any = i18nISOCountries.getNames('en');
if (typeof isoC === 'object') {
  Object.keys(isoC).forEach((key: any) => {
    countryList.push({
      code: key,
      name: isoC[key],
    });
  });
}
