import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { ReservationCategory } from '@/entity/ReservationCategory';

export const mergeReservationCategories = async (businessId: any, locationId: any, reservationCategories: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching reservation categories data... Please wait.');
  
  const dbConnection: any = await getDBConnection();
  if (reservationCategories && reservationCategories.length > 0) {
    for (const reservationCategory of reservationCategories) {
      let reservationCategoryEntity = await dbConnection
        .getRepository(ReservationCategory)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: reservationCategory.id })
        .getOne();
      let insertMode = false;
      if (!reservationCategoryEntity) {
        insertMode = true;
        reservationCategoryEntity = new ReservationCategory();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && reservationCategoryEntity.last_modified < reservationCategory.last_modified)) {
        reservationCategoryEntity.id = reservationCategory.id;
        reservationCategoryEntity.business_id = reservationCategory.business_id;
        reservationCategoryEntity.location_id = reservationCategory.location_id;
        reservationCategoryEntity.name = reservationCategory.name;
        reservationCategoryEntity.amount = reservationCategory.amount;
        reservationCategoryEntity.amount_suffix = reservationCategory.amount_suffix;
        reservationCategoryEntity.type = reservationCategory.type;
        reservationCategoryEntity.description = reservationCategory.description;
        reservationCategoryEntity.photo = reservationCategory.photo;
        reservationCategoryEntity.is_enabled = reservationCategory.is_enabled;
        reservationCategoryEntity.metadata = reservationCategory.metadata ? JSON.stringify(reservationCategory.metadata) : null;
        reservationCategoryEntity.created_at = reservationCategory.created_at;
        reservationCategoryEntity.updated_at = reservationCategory.updated_at;
        reservationCategoryEntity.last_modified = reservationCategory.last_modified;
        reservationCategoryEntity.isSynced = true;
        await dbConnection.getRepository(ReservationCategory).save(reservationCategoryEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedReservationCategories = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const reservationCategories = await dbConnection
    .getRepository(ReservationCategory)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return reservationCategories.length > 0 ? reservationCategories : null;
}