import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { LaundryOrder } from '@/entity/LaundryOrder';

export const mergeLaundryOrders = async (businessId: any, locationId: any, laundryOrders: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching laundry orders data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (laundryOrders && laundryOrders.length > 0) {
    for (const laundryOrder of laundryOrders) {
      let laundryOrderEntity = await dbConnection
        .getRepository(LaundryOrder)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: laundryOrder.id })
        .getOne();
      let insertMode = false;
      if (!laundryOrderEntity) {
        insertMode = true;
        laundryOrderEntity = new LaundryOrder();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && laundryOrderEntity.last_modified < laundryOrder.last_modified)) {
        laundryOrderEntity.id = laundryOrder.id;
        laundryOrderEntity.business_id = laundryOrder.business_id;
        laundryOrderEntity.location_id = laundryOrder.location_id;
        laundryOrderEntity.operator_id = laundryOrder.operator_id;
        laundryOrderEntity.customer_id = laundryOrder.customer_id;
        laundryOrderEntity.code = laundryOrder.code;
        laundryOrderEntity.subtotal = laundryOrder.subtotal;
        laundryOrderEntity.tax = laundryOrder.tax;
        laundryOrderEntity.discount = laundryOrder.discount;
        laundryOrderEntity.total = laundryOrder.total;
        laundryOrderEntity.paid_amount = laundryOrder.paid_amount;
        laundryOrderEntity.payment_status = laundryOrder.payment_status;
        laundryOrderEntity.payment_method = laundryOrder.payment_method;
        laundryOrderEntity.notes = laundryOrder.notes;
        laundryOrderEntity.status = laundryOrder.status;
        laundryOrderEntity.scheduled_delivery_at = laundryOrder.scheduled_delivery_at;
        laundryOrderEntity.delivered_at = laundryOrder.delivered_at;
        laundryOrderEntity.metadata = laundryOrder.metadata ? JSON.stringify(laundryOrder.metadata) : null;
        laundryOrderEntity.created_at = laundryOrder.created_at;
        laundryOrderEntity.updated_at = laundryOrder.updated_at;
        laundryOrderEntity.last_modified = laundryOrder.last_modified;
        laundryOrderEntity.isSynced = true;
        await dbConnection.getRepository(LaundryOrder).save(laundryOrderEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedLaundryOrders = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const laundryOrders = await dbConnection
    .getRepository(LaundryOrder)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return laundryOrders.length > 0 ? laundryOrders : null;
}