import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection, saveToWebStore } from '@/data-source';
import { DeletedRecord } from '@/entity/DeletedRecord';

export const mergeDeletedRecords = async (businessId: any, locationId: any, deletedRecords: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching deleted records data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (deletedRecords && deletedRecords.length > 0) {
    for (const deletedRecord of deletedRecords) {
      const deletedRecordEntity = await dbConnection
        .getRepository(DeletedRecord)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: deletedRecord.id })
        .getOne();
      if (deletedRecordEntity) {
        // Permanently delete the deleted record row, INTENTIONALLY used RAW SQL QUERY here instead of QueryBuilder to avoid broadcasting the event
        const [sql, params] = await dbConnection
          .createQueryBuilder()
          .delete()
          .from(DeletedRecord)
          .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
          .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
          .andWhere('LOWER(id) = LOWER(:id)', { id: deletedRecord.id })
          .getQueryAndParameters();
        await dbConnection.query(sql, params); 
        // Raw Query? For WEB platform, this is to save the database to web store to prevent loss of data
        saveToWebStore();
      }

      // check if the deleted record still available in the respective local database tables, then delete it
      // this is useful when the deleted record is not available in the remote database anymore or delete by other connected client 
      // but still exists in the local database, this time we will delete it from the local database and create new record DeletedRecords
      try {        
        const TargetEntity = await dbConnection.entityMetadatas.find((entityMetadata: any) => entityMetadata.tableName === deletedRecord.table_name);
        if (TargetEntity) {
          const targetEntity = await dbConnection
            .getRepository(TargetEntity)
            .createQueryBuilder()
            .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
            .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
            .andWhere('LOWER(id) = LOWER(:id)', { id: deletedRecord.deleted_id })
            .getOne();
            if (targetEntity) await dbConnection.getRepository(TargetEntity).remove(targetEntity);
        }
      } catch (e) {
        // console.log(e);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedDeletedRecords = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const deletedRecords = await dbConnection
    .getRepository(DeletedRecord)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return deletedRecords.length > 0 ? deletedRecords : null;
}