// import { reactive } from 'vue';
import { Capacitor } from '@capacitor/core';
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite';
import { defineCustomElements as jeepSqlite, applyPolyfills } from "jeep-sqlite/loader";
import "reflect-metadata";
import { DataSource } from "typeorm"
import config from "@/config";
import { getItem } from "@/utils/storage";
import { sleep } from '@/utils/helpers';
import { useDBConnectionStore } from '@/stores/dbConnectionStore';
// import entities
import { User } from "@/entity/User";
import { Business } from "@/entity/Business";
import { Location } from "@/entity/Location";
import { OperatorRole } from "@/entity/OperatorRole";
import { Operator } from "@/entity/Operator";
import { IncomeCategory } from "@/entity/IncomeCategory";
import { Income } from "@/entity/Income";
import { ExpenseCategory } from "@/entity/ExpenseCategory";
import { Expense } from "@/entity/Expense";
import { Customer } from "@/entity/Customer";
import { ParkingTicket } from "@/entity/ParkingTicket";
import { PoolCatalogue } from "@/entity/PoolCatalogue";
import { PoolTicket } from "@/entity/PoolTicket";
import { LaundryServiceList } from "@/entity/LaundryServiceList";
import { LaundryOrder } from "@/entity/LaundryOrder";
import { LaundryOrderItem } from "@/entity/LaundryOrderItem";
import { ReservationCategory } from "@/entity/ReservationCategory";
import { ReservationFacility } from "@/entity/ReservationFacility";
import { Reservation } from "@/entity/Reservation";
import { InventoryCategory } from "@/entity/InventoryCategory";
import { InventorySupplier } from "@/entity/InventorySupplier";
import { InventoryLocation } from "@/entity/InventoryLocation";
import { InventoryItem } from "@/entity/InventoryItem";
import { InventoryPurchaseOrder } from "@/entity/InventoryPurchaseOrder";
import { InventoryPurchaseOrderItem } from "@/entity/InventoryPurchaseOrderItem";
import { InventoryStock } from "@/entity/InventoryStock";
import { InventoryStockHistory } from "@/entity/InventoryStockHistory";
import { InventoryStockAdjustment } from "@/entity/InventoryStockAdjustment";
import { InventoryStockAdjustmentItem } from "@/entity/InventoryStockAdjustmentItem";
import { SalesOrder } from "@/entity/SalesOrder";
import { SalesOrderItem } from "@/entity/SalesOrderItem";
import { BookingOrder } from "@/entity/BookingOrder";
import { BookingOrderItem } from "@/entity/BookingOrderItem";
// 
import { Device } from "@/entity/Device";
import { Audit } from "@/entity/Audit";
import { DeletedRecord } from "@/entity/DeletedRecord";
// import migrations
// import { UserMigration } from "@/migrations/UserMigration";
// import { UsersTable16509352621001 } from "@/migration/16509352621001-UsersTable";

// event subscriber
import { AllEntityEventSubscriber } from './data-source-all-entity-event-subscriber';

applyPolyfills().then(() => {
  jeepSqlite(window);
});

const platform = Capacitor.getPlatform();
// create a SQLite Connection Wrapper
const sqliteConnection: SQLiteConnection = new SQLiteConnection(CapacitorSQLite);


/**
 * Get database connection
 * 
 * @param overrideDatabaseName 
 * @returns 
 */
export async function getDBConnection(replaceDatabaseName = ''): Promise<DataSource|null> {
  let databaseName = '';
  if (replaceDatabaseName != '' && typeof replaceDatabaseName === "string") {
    databaseName = replaceDatabaseName;
  } else {
    databaseName = await getItem(config.localStorageKeyNames.locationId);    
  }
  if (databaseName) {
    if (typeof databaseName !== 'string') {
      databaseName = `db${databaseName}`;
    }
  } else {
    // databaseName = 'reterdefault';
    databaseName = '';
  }
  if (databaseName) { 
    const dbConnectionStore = useDBConnectionStore();
    const existingConnection: any = dbConnectionStore.connection;
    if (existingConnection && existingConnection?.isInitialized) {
      try {
          return existingConnection;
      } catch (error) {
        return getDataSource(databaseName);
      }
    } else {
      return getDataSource(databaseName);
    }
  } else {
    return Promise.resolve(null);
  }
}


/**
   * Initialize the database. On device does nothing. On web, sets up the
   * IndexDB database, if it doesn't exist.
   */
 const initializeDB = async () => {
  if (platform === "web") {
    // Create the 'jeep-sqlite' Stencil component if it doesn't exist
    if (!document.querySelector("jeep-sqlite")) {      
      const jeepSqlite = document.createElement('jeep-sqlite');
      document.body.appendChild(jeepSqlite);
    }
    await customElements.whenDefined('jeep-sqlite');
    const jeepSqliteEl = document.querySelector('jeep-sqlite');
    if (jeepSqliteEl != null) {
      // Initialize the Web store
      await sqliteConnection.initWebStore();
    } else {
      throw Error('jeepSqliteEl is null');
    }    
  }
}

/**
 * For web, this is to save the database to web store to prevent loss of data
 * @param databaseName 
 */
export const saveToWebStore = async (databaseName: string | null = null) => {
  if (platform === "web") {
    const dbConnectionStore = useDBConnectionStore();
    const locationId = await getItem(config.localStorageKeyNames.locationId);
    const database: any = databaseName || dbConnectionStore.database || locationId;
    const jeepSqliteEl = document.querySelector('jeep-sqlite');
    if (jeepSqliteEl != null && database) {
      const isStoreOpen = await jeepSqliteEl.isStoreOpen();
      const res = await sqliteConnection.isConnection(database);
      if (isStoreOpen && res.result) {
        await jeepSqliteEl.saveToStore({ database: database });
      }
    }
  }
}


/**
 * Get DataSource
 * 
 * @param databaseName name of the database
 * @returns 
 */
export async function getDataSource(databaseName: string, attempt = 1): Promise<DataSource|null> {
  const dbConnectionStore = useDBConnectionStore();
  try {
    if (platform === "web") {
      // Check if user is running app in Private Mode/Icognito Mode with no support for IndexedDB
      const testDB = indexedDB.open('test');
      testDB.onerror = function () {
        attempt = -1;
        alert("This app does not support private browsing mode. Please disable private browsing mode.");
        throw new Error("This app does not support private browsing mode. Please disable private browsing mode.");
        return;
      };
    }
    
    // Initialize the database. On device does nothing. On web, sets up the
    initializeDB();

    dbConnectionStore.setDatabase(databaseName);
    dbConnectionStore.setSQLite(sqliteConnection);

    // when using Capacitor, you might want to close existing connections, 
    // otherwise new connections will fail when using dev-live-reload
    // see https://github.com/capacitor-community/sqlite/issues/106
    CapacitorSQLite.checkConnectionsConsistency({
        dbNames: [databaseName], // i.e. "i expect no connections to be open"
    }).catch(() => { // (e)
        // the plugin throws an error when closing connections. we can ignore
        // that since it is expected behaviour
        return null;
    });

    // Copy databases from public/assets/databases folder to application databases folder
    // copy preloaded dbs (optional, not TypeORM related):
    // the preloaded dbs must have the `YOUR_DB_NAME.db` format (i.e. including 
    // the `.db` suffix, NOT including the internal `SQLITE` suffix from the plugin)
    // await sqliteConnection.copyFromAssets();

    // create the TypeORM data source and connection
    const dataSource = new DataSource({
      type: "capacitor", 
      driver: sqliteConnection,
      database: databaseName,
      mode: 'no-encryption', // Set the mode for database encryption: "no-encryption" | "encryption" | "secret" | "newsecret"
      journalMode: 'WAL', // Set the journal mode for database: "DELETE" | "TRUNCATE" | "PERSIST" | "WAL" | "OFF"
      // cache: true,
      // synchronize: process.env.NODE_ENV === 'development',
      synchronize: true, // disable in production mode
      migrationsRun: false, /// will use this production mode
      logging: false,
      entities: [
        User,
        Business,
        Location,
        OperatorRole,
        Operator,
        IncomeCategory,
        Income,
        ExpenseCategory,
        Expense,
        Customer,
        ParkingTicket,
        PoolCatalogue,
        PoolTicket,
        LaundryServiceList,
        LaundryOrder,
        LaundryOrderItem,
        ReservationCategory,
        ReservationFacility,
        Reservation,
        InventoryCategory,
        InventorySupplier,
        InventoryLocation,
        InventoryItem,
        InventoryPurchaseOrder,
        InventoryPurchaseOrderItem,
        InventoryStock,
        InventoryStockHistory,
        InventoryStockAdjustment,
        InventoryStockAdjustmentItem,
        SalesOrder,
        SalesOrderItem,
        BookingOrder,
        BookingOrderItem,
        // 
        Device,
        Audit,
        DeletedRecord,
      ],
      migrations: [
        // UsersTable16509352621001,
      ],
      subscribers: [
        AllEntityEventSubscriber,
      ],
      migrationsTableName: 'migrations',
      // cli: {
      //   migrationsDir: 'src/migration',
      //   entitiesDir: 'src/entity',
      // }
    });
    // to initialize initial connection with the database, register all entities
    // and "synchronize" database schema, call "initialize()" method of a newly created database
    // once in your application bootstrap
    
    if (!dataSource.isInitialized) {
      await dataSource.initialize();
    }
    // AppDataSource.query('PRAGMA journal_mode=WAL2;');
    dbConnectionStore.setConnection(dataSource);
    return dataSource;
    
  } catch (err) {
    if (attempt <= -1 || attempt >= 1000) { 
      dbConnectionStore.resetConnection();
      if (config.isDev) {
        throw new Error('Giving up after too many connection attempts: ' + err);
      } else {
        throw new Error('Database Connection Failed:');
      }
    }

    await sleep(100); //  * attempt
    if (config.isDev) console.log(`Retrying connection attempt ${attempt}`);
    return getDataSource(databaseName, attempt + 1);  
  }
}



