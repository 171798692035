import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { InventoryCategory } from '@/entity/InventoryCategory';

export const mergeInventoryCategories = async (businessId: any, locationId: any, inventoryCategories: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching inventory categories data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (inventoryCategories && inventoryCategories.length > 0) {
    for (const inventoryCategory of inventoryCategories) {
      let inventoryCategoryEntity = await dbConnection
        .getRepository(InventoryCategory)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: inventoryCategory.id })
        .getOne();
      let insertMode = false;
      if (!inventoryCategoryEntity) {
        insertMode = true;
        inventoryCategoryEntity = new InventoryCategory();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && inventoryCategoryEntity.last_modified < inventoryCategory.last_modified)) {
        inventoryCategoryEntity.id = inventoryCategory.id;
        inventoryCategoryEntity.business_id = inventoryCategory.business_id;
        inventoryCategoryEntity.location_id = inventoryCategory.location_id;
        inventoryCategoryEntity.name = inventoryCategory.name;
        inventoryCategoryEntity.description = inventoryCategory.description;
        inventoryCategoryEntity.is_enabled = inventoryCategory.is_enabled;
        inventoryCategoryEntity.created_at = inventoryCategory.created_at;
        inventoryCategoryEntity.updated_at = inventoryCategory.updated_at;
        inventoryCategoryEntity.last_modified = inventoryCategory.last_modified;
        inventoryCategoryEntity.isSynced = true;
        await dbConnection.getRepository(InventoryCategory).save(inventoryCategoryEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedInventoryCategories = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const inventoryCategories = await dbConnection
    .getRepository(InventoryCategory)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return inventoryCategories.length > 0 ? inventoryCategories : null;
}