import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { Reservation } from '@/entity/Reservation';

export const mergeReservations = async (businessId: any, locationId: any, reservations: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching reservations data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (reservations && reservations.length > 0) {
    for (const reservation of reservations) {
      let reservationEntity = await dbConnection
        .getRepository(Reservation)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: reservation.id })
        .getOne();
      let insertMode = false;
      if (!reservationEntity) {
        insertMode = true;
        reservationEntity = new Reservation();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && reservationEntity.last_modified < reservation.last_modified)) {
        reservationEntity.id = reservation.id;
        reservationEntity.business_id = reservation.business_id;
        reservationEntity.location_id = reservation.location_id;
        reservationEntity.operator_id = reservation.operator_id;
        reservationEntity.customer_id = reservation.customer_id;
        reservationEntity.reservation_facility_id = reservation.reservation_facility_id;
        reservationEntity.code = reservation.code;
        reservationEntity.checkin_at = reservation.checkin_at;
        reservationEntity.checkout_at = reservation.checkout_at;
        reservationEntity.entered_at = reservation.entered_at;
        reservationEntity.exited_at = reservation.exited_at;
        reservationEntity.subtotal = reservation.subtotal;
        reservationEntity.tax = reservation.tax;
        reservationEntity.discount = reservation.discount;
        reservationEntity.total = reservation.total;
        reservationEntity.paid_amount = reservation.paid_amount;
        reservationEntity.payment_status = reservation.payment_status;
        reservationEntity.payment_method = reservation.payment_method;
        reservationEntity.notes = reservation.notes;
        reservationEntity.status = reservation.status;
        reservationEntity.metadata = reservation.metadata ? JSON.stringify(reservation.metadata) : null;
        reservationEntity.created_at = reservation.created_at;
        reservationEntity.updated_at = reservation.updated_at;
        reservationEntity.last_modified = reservation.last_modified;
        reservationEntity.isSynced = true;
        await dbConnection.getRepository(Reservation).save(reservationEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedReservations = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const reservations = await dbConnection
    .getRepository(Reservation)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return reservations.length > 0 ? reservations : null;
}