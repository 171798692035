
import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm";

@Entity({ name: 'inventory_suppliers' })
@Index(['business_id', 'location_id', 'id'])
@Index(['business_id', 'location_id', 'last_modified'])
@Index(['business_id', 'location_id', 'isSynced'])
@Index(['business_id', 'location_id', 'name', 'email', 'phone'])
export class InventorySupplier extends BaseEntity {
  @PrimaryColumn()
  id: string;

  @Column() 
  @Index()
  business_id: string;

  @Column() 
  @Index()
  location_id: string;

  @Column() 
  name: string;

  @Column({ nullable: true })
  email: string;

  @Column({ nullable: true }) 
  phone: string;

  @Column({ type: 'text', nullable: true })
  address: string;

  @Column({ nullable: true }) 
  city: string;

  @Column({ nullable: true }) 
  state: string;

  @Column({ nullable: true }) 
  country: string;

  @Column({ default: true })
  is_enabled: boolean;

  @Column({ type: 'text', nullable: true })
  metadata: string;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;
}