import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm";

@Entity({ name: 'locations' })
@Index(['business_id', 'id', 'last_modified'])
@Index(['business_id', 'id', 'isSynced'])
export class Location extends BaseEntity {
  @PrimaryColumn()
  id: string;

  @Column() 
  @Index()
  business_id: string;

  @Column() 
  name: string;

  @Column({ type: 'text', nullable: true })
  address: string;

  @Column({ nullable: true }) 
  city: string;

  @Column({ nullable: true }) 
  state: string;

  @Column({ nullable: true }) 
  country: string;

  @Column({ default: 'active' })
  status: string;

  @Column({ nullable: true }) 
  timezone: string;

  @Column({ type: 'text', nullable: true })
  metadata: string;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;
}