import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { InventoryStockAdjustment } from '@/entity/InventoryStockAdjustment';

export const mergeInventoryStockAdjustments = async (businessId: any, locationId: any, inventoryStockAdjustments: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching inventory stock adjustments data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (inventoryStockAdjustments && inventoryStockAdjustments.length > 0) {
    for (const inventoryStockAdjustment of inventoryStockAdjustments) {
      let inventoryStockAdjustmentEntity = await dbConnection
        .getRepository(InventoryStockAdjustment)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: inventoryStockAdjustment.id })
        .getOne();
      let insertMode = false;
      if (!inventoryStockAdjustmentEntity) {
        insertMode = true;
        inventoryStockAdjustmentEntity = new InventoryStockAdjustment();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && inventoryStockAdjustmentEntity.last_modified < inventoryStockAdjustment.last_modified)) {
        inventoryStockAdjustmentEntity.id = inventoryStockAdjustment.id;
        inventoryStockAdjustmentEntity.business_id = inventoryStockAdjustment.business_id;
        inventoryStockAdjustmentEntity.location_id = inventoryStockAdjustment.location_id;
        inventoryStockAdjustmentEntity.operator_id = inventoryStockAdjustment.operator_id;
        inventoryStockAdjustmentEntity.inventory_location_id = inventoryStockAdjustment.inventory_location_id;
        inventoryStockAdjustmentEntity.code = inventoryStockAdjustment.code;
        inventoryStockAdjustmentEntity.type = inventoryStockAdjustment.type;
        inventoryStockAdjustmentEntity.total_cost = inventoryStockAdjustment.total_cost;
        inventoryStockAdjustmentEntity.total_quantity = inventoryStockAdjustment.total_quantity;
        inventoryStockAdjustmentEntity.notes = inventoryStockAdjustment.notes;
        inventoryStockAdjustmentEntity.metadata = inventoryStockAdjustment.metadata ? JSON.stringify(inventoryStockAdjustment.metadata) : null;
        inventoryStockAdjustmentEntity.created_at = inventoryStockAdjustment.created_at;
        inventoryStockAdjustmentEntity.updated_at = inventoryStockAdjustment.updated_at;
        inventoryStockAdjustmentEntity.last_modified = inventoryStockAdjustment.last_modified;
        inventoryStockAdjustmentEntity.isSynced = true;
        await dbConnection.getRepository(InventoryStockAdjustment).save(inventoryStockAdjustmentEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedInventoryStockAdjustments = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const inventoryStockAdjustments = await dbConnection
    .getRepository(InventoryStockAdjustment)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return inventoryStockAdjustments.length > 0 ? inventoryStockAdjustments : null;
}