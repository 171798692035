
import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm"; // AfterRemove, RemoveEvent
// import { getDBConnection } from "@/data-source";
// import { Operator } from "@/entity/Operator";

@Entity({ name: 'operator_roles' }) 
@Index(['business_id', 'location_id', 'id'])
@Index(['business_id', 'location_id', 'last_modified'])
@Index(['business_id', 'location_id', 'isSynced'])
export class OperatorRole extends BaseEntity {
  @PrimaryColumn()
  id: string;

  @Column() 
  @Index()
  business_id: string;

  @Column() 
  @Index()
  location_id: string;

  @Column() 
  name: string;

  @Column({ type: 'text', nullable: true })
  description: string;

  @Column({ type: 'text', nullable: true })
  permissions: string;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;

  // // detach operator role from the operators record it has been assigned to.
  // @AfterRemove()
  // async detachRoleFromOperators(event: RemoveEvent<any>) {
  //   console.log('DETACH', event);
  //   console.log('DETACH2', this);
  //   if (event) {
  //     const operatorRoleId = this.id;
  //     // const dbConnection: any = event.connection; // using the existing connection
  //     const dbConnection: any = await getDBConnection();
  //     await dbConnection
  //     .createQueryBuilder()
  //     .update(Operator)
  //     .set({
  //       operator_role_id: null,
  //       last_modified: Date.now(),
  //     })
  //     .where("LOWER(operator_role_id) = LOWER(:id)", { operatorRoleId })
  //     .execute();
  //     alert('Role detached')
  //   }
  // }

}