import config from '@/config';
import { removeItem } from '@/utils/storage';
import router from '@/router';
import { useAuthStore } from '@/stores/authStore';

export function useLogOutAccount(logOutConfirmation = true, toRoute: any = null) {
  const authStore = useAuthStore();

  let response = false;
  if (logOutConfirmation) {
    response = confirm('Log account from this device? Ensure you are connected to the internet to backup your local database to the remote server, before deactivating a device .');
  } else {
    response = true;
  }
  if (response) {
    removeItem(config.localStorageKeyNames.token);
    removeItem(config.localStorageKeyNames.tokenExpireIn);
    removeItem(config.localStorageKeyNames.tokenExpireAt);
    removeItem(config.localStorageKeyNames.userId);
    removeItem(config.localStorageKeyNames.businessId);
    removeItem(config.localStorageKeyNames.locationId);
    removeItem(config.localStorageKeyNames.currentOperatorId);
    removeItem(config.localStorageKeyNames.isAuthorized);
    removeItem(config.localStorageKeyNames.signedIn);
    removeItem(config.localStorageKeyNames.salesInventoryLocation);
    authStore.$reset();

    if (logOutConfirmation) {
      alert('Account removed from this device successfully.');
    }
    if (toRoute !== false) {
      if (toRoute) {
        router.push(toRoute);
      } else {
        router.push({ name: 'home' });
      }
    }
  }
}

export function useLogOutOperator(logOutConfirmation = true, toRoute: any = null)  {
  const authStore = useAuthStore();

  let response = false;
  if (logOutConfirmation) {
    response = confirm('Sign Out?');
  } else {
    response = true;
  }
  if (response) {
    removeItem(config.localStorageKeyNames.signedIn);
    authStore.$patch((state: any) => {
      state.operatorSignedIn = false;
      state.operatorPermissions = [];
    });
    if (logOutConfirmation) {
      // alert('You have been removed');
    }
    if (toRoute !== false) {
      if (toRoute) {
        router.push(toRoute);
      } else {
        router.push({ name: 'lockscreen' });
      }
    }
  }
}
