import { setItem, getItem, removeItem, getAllItemKeys } from '@/utils/storage'
import config from '@/config'

const darkModeToggle = async (mode: string | null) => {
  if (mode == 'light') {
    await setItem(config.localStorageKeyNames.colorScheme, 'light');
  } else if (mode == 'dark') {
    await setItem(config.localStorageKeyNames.colorScheme, 'dark');
  } else {
    await removeItem(config.localStorageKeyNames.colorScheme);
  }
  checkDarkMode();
}

const checkDarkMode = async () => {
  const storageKeys = await getAllItemKeys();
  const scheme = await getColorScheme();
  if (scheme === 'dark' || (!(storageKeys.includes(config.localStorageKeyNames.colorScheme)) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.documentElement.classList.add('dark')
    document.body.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
    document.body.classList.remove('dark')
  }
}

const getColorScheme = async () => {
  return await getItem(config.localStorageKeyNames.colorScheme);
}

const setThemeColor = async (colorCode: any = null) => {
  let themeColor = '';
  if (colorCode) {
    await setItem(config.localStorageKeyNames.themeColor, colorCode);
    themeColor = colorCode;
  } else {
    themeColor = await getItem(config.localStorageKeyNames.themeColor);
  }
  if (!themeColor) {
    // themeColor = '#00aded';
    themeColor = '#008F36';
  }
  const primaryColor = themeColor;
  const primaryDarkColor = adjustColorBrightness(themeColor, 0);
  const primary900Color = adjustColorBrightness(themeColor, 1);
  const primary800Color = adjustColorBrightness(themeColor, 10);
  const primary700Color = adjustColorBrightness(themeColor, 20);
  const primary600Color = adjustColorBrightness(themeColor, 30);
  const primary500Color = adjustColorBrightness(themeColor, 40);
  const primary400Color = adjustColorBrightness(themeColor, 50);
  const primary300Color = adjustColorBrightness(themeColor, 60);
  const primary200Color = adjustColorBrightness(themeColor, 70);
  const primary100Color = adjustColorBrightness(themeColor, 80);
  const primary50Color = adjustColorBrightness(themeColor, 90);
  const primaryLightColor = adjustColorBrightness(themeColor, 95);

  document.documentElement.style.setProperty('--primary-color', primaryColor);
  document.documentElement.style.setProperty('--primary-light-color', primaryLightColor);
  document.documentElement.style.setProperty('--primary-dark-color', primaryDarkColor);
  document.documentElement.style.setProperty('--primary-900-color', primary900Color);
  document.documentElement.style.setProperty('--primary-800-color', primary800Color);
  document.documentElement.style.setProperty('--primary-700-color', primary700Color);
  document.documentElement.style.setProperty('--primary-600-color', primary600Color);
  document.documentElement.style.setProperty('--primary-500-color', primary500Color);
  document.documentElement.style.setProperty('--primary-400-color', primary400Color);
  document.documentElement.style.setProperty('--primary-300-color', primary300Color);
  document.documentElement.style.setProperty('--primary-200-color', primary200Color);
  document.documentElement.style.setProperty('--primary-100-color', primary100Color);
  document.documentElement.style.setProperty('--primary-50-color', primary50Color);
  // // the css equivalent 
  // :root {
  //   --primary-color: {{ $primary_color }};
  //   --primary-light-color: {{ adjustColorBrightness($primary_color, 30) }};
  //   --primary-dark-color: {{ adjustColorBrightness($primary_color, 50) }};
  // }
}


/**
 * Increases or decreases the brightness of a color by a percentage of the current brightness.
 *
 * @param   string  $hexCode        Supported formats: `#FFF`, `#FFFFFF`, `FFF`, `FFFFFF`
 * @param   number   $adjustPercent  A number between -1 and 100. E.g. -50 = 50% darker, 30 = 30% lighter;.
 * @return  string
 * 
 * @source https://stackoverflow.com/questions/6443990/javascript-calculate-brighter-colour
 */
const adjustColorBrightness = (hexCode: string, adjustPercent: number) => {
  // strip the leading # if it's there
  hexCode = hexCode.replace(/^\s*#|\s*$/g, '');
  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if(hexCode.length == 3){
    hexCode = hexCode.replace(/(.)/g, '$1$1');
  }
  const r = parseInt(hexCode.substr(0, 2), 16),
      g = parseInt(hexCode.substr(2, 2), 16),
      b = parseInt(hexCode.substr(4, 2), 16);
  const newColor =  '#'
    + ((0|(1<<8) + r + (256 - r) * adjustPercent / 100).toString(16)).substr(1)
    + ((0|(1<<8) + g + (256 - g) * adjustPercent / 100).toString(16)).substr(1)
    + ((0|(1<<8) + b + (256 - b) * adjustPercent / 100).toString(16)).substr(1);
  // 
  // console.log(hexCode, '(', adjustPercent, ') = ', newColor);
  return newColor;
}


export {
  darkModeToggle,
  checkDarkMode,
  getColorScheme,
  setThemeColor
};