
import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm";

@Entity({ name: 'inventory_categories' }) 
@Index(['business_id', 'location_id', 'id'])
@Index(['business_id', 'location_id', 'last_modified'])
@Index(['business_id', 'location_id', 'isSynced'])
export class InventoryCategory extends BaseEntity {
  @PrimaryColumn()
  id: string;

  @Column() 
  @Index()
  business_id: string;

  @Column() 
  @Index()
  location_id: string;

  @Column() 
  name: string;

  @Column({ type: 'text', nullable: true })
  description: string;

  @Column() 
  is_enabled: boolean;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;
}