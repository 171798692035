import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { ExpenseCategory } from '@/entity/ExpenseCategory';

export const mergeExpenseCategories = async (businessId: any, locationId: any, expenseCategories: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching expense categories data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (expenseCategories && expenseCategories.length > 0) {
    for (const expenseCategory of expenseCategories) {
      let expenseCategoryEntity = await dbConnection
        .getRepository(ExpenseCategory)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: expenseCategory.id })
        .getOne();
      let insertMode = false;
      if (!expenseCategoryEntity) {
        insertMode = true;
        expenseCategoryEntity = new ExpenseCategory();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && expenseCategoryEntity.last_modified < expenseCategory.last_modified)) {
        expenseCategoryEntity.id = expenseCategory.id;
        expenseCategoryEntity.business_id = expenseCategory.business_id;
        expenseCategoryEntity.location_id = expenseCategory.location_id;
        expenseCategoryEntity.name = expenseCategory.name;
        expenseCategoryEntity.description = expenseCategory.description;
        expenseCategoryEntity.is_enabled = expenseCategory.is_enabled;
        expenseCategoryEntity.created_at = expenseCategory.created_at;
        expenseCategoryEntity.updated_at = expenseCategory.updated_at;
        expenseCategoryEntity.last_modified = expenseCategory.last_modified;
        expenseCategoryEntity.isSynced = true;
        await dbConnection.getRepository(ExpenseCategory).save(expenseCategoryEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedExpenseCategories = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const expenseCategories = await dbConnection
    .getRepository(ExpenseCategory)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return expenseCategories.length > 0 ? expenseCategories : null;
}