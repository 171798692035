import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { Device } from '@/entity/Device';

export const mergeDevices = async (businessId: any, locationId: any, devices: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching booking orders data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (devices && devices.length > 0) {
    for (const device of devices) {
      let deviceEntity = await dbConnection
        .getRepository(Device)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: device.id })
        .getOne();
      let insertMode = false;
      if (!deviceEntity) {
        insertMode = true;
        deviceEntity = new Device();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && deviceEntity.last_modified < device.last_modified)) {
        deviceEntity.id = device.id;
        deviceEntity.business_id = device.business_id;
        deviceEntity.location_id = device.location_id;
        deviceEntity.operator_id = device.operator_id;
        deviceEntity.type = device.type;
        deviceEntity.connected_device_id = device.connected_device_id;
        deviceEntity.name = device.name;
        deviceEntity.metadata = device.metadata ? JSON.stringify(device.metadata) : null;
        deviceEntity.created_at = device.created_at;
        deviceEntity.updated_at = device.updated_at;
        deviceEntity.last_modified = device.last_modified;
        deviceEntity.isSynced = true;
        await dbConnection.getRepository(Device).save(deviceEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedDevices = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const devices = await dbConnection
    .getRepository(Device)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return devices.length > 0 ? devices : null;
}