import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { PoolTicket } from '@/entity/PoolTicket';

export const mergePoolTickets = async (businessId: any, locationId: any, poolTickets: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching pool tickets data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (poolTickets && poolTickets.length > 0) {
    for (const poolTicket of poolTickets) {
      let poolTicketEntity = await dbConnection
        .getRepository(PoolTicket)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: poolTicket.id })
        .getOne();
      let insertMode = false;
      if (!poolTicketEntity) {
        insertMode = true;
        poolTicketEntity = new PoolTicket();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && poolTicketEntity.last_modified < poolTicket.last_modified)) {
        poolTicketEntity.id = poolTicket.id;
        poolTicketEntity.business_id = poolTicket.business_id;
        poolTicketEntity.location_id = poolTicket.location_id;
        poolTicketEntity.operator_id = poolTicket.operator_id;
        poolTicketEntity.customer_id = poolTicket.customer_id;
        poolTicketEntity.pool_catalogue_id = poolTicket.pool_catalogue_id;
        poolTicketEntity.code = poolTicket.code;
        poolTicketEntity.amount = poolTicket.amount;
        poolTicketEntity.notes = poolTicket.notes;
        poolTicketEntity.status = poolTicket.status;
        poolTicketEntity.scheduled_at = poolTicket.scheduled_at;
        poolTicketEntity.used_at = poolTicket.used_at;
        poolTicketEntity.metadata = poolTicket.metadata ? JSON.stringify(poolTicket.metadata) : null;
        poolTicketEntity.created_at = poolTicket.created_at;
        poolTicketEntity.updated_at = poolTicket.updated_at;
        poolTicketEntity.last_modified = poolTicket.last_modified;
        poolTicketEntity.isSynced = true;
        await dbConnection.getRepository(PoolTicket).save(poolTicketEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedPoolTickets = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const poolTickets = await dbConnection
    .getRepository(PoolTicket)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return poolTickets.length > 0 ? poolTickets : null;
}