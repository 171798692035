import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm";

@Entity({ name: 'inventory_items' })
@Index(['business_id', 'location_id', 'id'])
@Index(['business_id', 'location_id', 'last_modified'])
@Index(['business_id', 'location_id', 'isSynced'])
@Index(['business_id', 'location_id', 'inventory_category_id'])
@Index(['business_id', 'location_id', 'sku'])
export class InventoryItem extends BaseEntity {
  @PrimaryColumn()
  id: string;

  @Column()
  @Index()
  business_id: string;

  @Column() 
  @Index()
  location_id: string;

  @Column()
  @Index()
  inventory_category_id: string;

  @Column({ nullable: true })
  @Index()
  inventory_supplier_id: string;

  @Column({ nullable: true })
  @Index()
  sku: string;

  @Column({ nullable: true })
  @Index()
  barcode: string;

  @Column() 
  name: string;

  @Column({ type: 'text', nullable: true })
  description: string;

  @Column({ default: false })
  for_sale: boolean;

  @Column({ default: 'each' }) // each, weight, volume
  sold_by: string;

  @Column({ type: 'decimal', precision: 26, scale: 8, default: 0.0 })
  price: string;

  @Column({ type: 'decimal', precision: 26, scale: 8, nullable: true })
  sale_price: string;

  @Column({ type: 'decimal', precision: 26, scale: 8, default: 0.0 })
  cost_price: string;

  @Column({ default: false })
  track_stock: boolean;

  @Column({ type: 'float', precision: 19, scale: 4, nullable: true })
  low_stock_quantity: number;

  @Column({ type: 'float', precision: 19, scale: 4, nullable: true })
  optimal_order_quantity: number;

  @Column({ type: 'date', nullable: true })
  production_date: Date;

  @Column({ type: 'date', nullable: true })
  expiry_date: Date;

  @Column({ type: 'text', nullable: true })
  photo: string;

  @Column()
  is_enabled: boolean;

  @Column({ type: 'text', nullable: true })
  metadata: string;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;
}