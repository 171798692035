import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm";

@Entity({ name: 'inventory_stock_adjustments' })
@Index(['business_id', 'location_id', 'id'])
@Index(['business_id', 'location_id', 'last_modified'])
@Index(['business_id', 'location_id', 'isSynced'])
@Index(['business_id', 'location_id', 'code'])
@Index(['business_id', 'location_id', 'type'])
export class InventoryStockAdjustment extends BaseEntity {
  @PrimaryColumn()
  id: string;

  @Column() 
  @Index()
  business_id: string;

  @Column({ nullable: true })
  @Index()
  operator_id: string;

  @Column() 
  @Index()
  location_id: string;

  @Column()
  @Index()
  inventory_location_id: string;

  @Column() 
  @Index()
  code: string;

  @Column({ default: 'receive_items' }) // receive_items, inventory_count, loss, damage
  type: string;

  @Column({ type: 'float', precision: 19, scale: 4, default: 0 })
  total_quantity: number;

  @Column({ type: 'decimal', precision: 26, scale: 8, default: 0.0 })
  total_cost: string;

  @Column({ type: 'text', nullable: true })
  notes: string;

  @Column({ type: 'text', nullable: true })
  metadata: string;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;
}