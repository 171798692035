import { createApp } from 'vue';
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router';
const pinia = createPinia(); // Pinia store instance
import acl from './acl'; // import the instance of the defined ACL

import axios from './axios';
import VueAxios from 'vue-axios';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { IonicVue } from '@ionic/vue';
import './ionic-vue';

import { setThemeColor, checkDarkMode } from '@/theme-toggle';

import vClickOutside from "click-outside-vue3";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
library.add(faDesktop);

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
const VueLoadingConfig = {
  canCancel: false, // default false
  // onCancel: this.yourCallbackMethod,
  color: '#000000',
  loader: 'spinner',
  width: 64,
  height: 64,
  backgroundColor: '#ffffff',
  opacity: 0.2,
  // zIndex: 999,
}

import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css';

import Datepicker from 'vue3-datepicker';

// import DateRangePicker, { CalendarDialog } from 'vue-time-date-range-picker/dist/vdprDatePicker';
import { default as DateRangePicker, CalendarDialog } from 'vue-time-date-range-picker/dist/vdprDatePicker';
import 'vue-time-date-range-picker/dist/vdprDatePicker.min.css';

import Cleave from 'cleave.js';

// import { NativeAudio } from '@capacitor-community/native-audio';
// NativeAudio.preload({
//   assetId: "glass_ping",
//   assetPath: "glass_ping.wav",
//   audioChannelNum: 1,
//   isUrl: false
// });
// NativeAudio.setVolume({
//   assetId: 'glass_ping',
//   volume: 1.0,
// });

// lastly, import the app custom styles
import './theme/style.css';

//  create app
const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(pinia)
  .use(acl)
  .use(VueAxios, axios)
  .use(vClickOutside)
  .use(VueLoading, VueLoadingConfig)
  // global components
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('Multiselect', Multiselect)
  .component('datepicker', Datepicker)
  .component('DateRangePicker', DateRangePicker)
  .component('CalendarDialog', CalendarDialog);

// app.config.globalProperties.dbConnection = dbConnection;
app.provide('axios', app.config.globalProperties.axios)  // provide 'axios'

// global directives
app.directive('cleave', {
  created: (el: any, binding: any) => {
    el.cleave = new Cleave(el, binding.value || {})
  },
  updated: (el: any) => {
    const event = new Event('input', { bubbles: true });
    setTimeout(function () {
      // el.value = el.cleave.properties.result // disabled intentionally to prevent infinite loop
      el.dispatchEvent(event)
    }, 100);
  }
});


router.isReady().then(() => {
  // Call the PWA element loader after the app has been rendered the first time
  defineCustomElements(window);
  // check and set theme color and dark mode 
  setThemeColor();  
  checkDarkMode();
  // mount app
  app.mount('#app');
});