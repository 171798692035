import { 
    EventSubscriber, 
    EntitySubscriberInterface, 
    InsertEvent, 
    UpdateEvent, 
    RemoveEvent, 
    TransactionCommitEvent,
    TransactionRollbackEvent,
} from 'typeorm';
import { DeletedRecord } from '@/entity/DeletedRecord';
import config from '@/config';
import { getID } from '@/utils/unique-id';
import { setItem } from '@/utils/storage';
import { saveToWebStore } from '@/data-source';
import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { useDBConnectionStore } from '@/stores/dbConnectionStore';

@EventSubscriber()
export class AllEntityEventSubscriber implements EntitySubscriberInterface {

  /**
   * Called after entity is loaded.
   */
  async afterLoad(entity: any) {
    if (config.isDev && config.logging) console.log(`AFTER ENTITY LOADED: `, entity);

    // set database use status
    // const dbConnectionStore = useDBConnectionStore();
    // dbConnectionStore.setInUse(false);

    // set last activity time
    setItem(config.localStorageKeyNames.lastActivityAt, Date.now());
  }


  /**
   * Called before entity insertion.
   */
  beforeInsert(event: InsertEvent<any>) {
    if (config.isDev && config.logging) console.log(`BEFORE ENTITY INSERTED: `, event.entity);

    // set database use status
    const dbConnectionStore = useDBConnectionStore();
    dbConnectionStore.setInUse(true);
  }


  /**
   * Called after entity insertion.
   */
  async afterInsert(event: InsertEvent<any>) {
    if (config.isDev && config.logging) console.log(`AFTER ENTITY INSERTED: `, event.entity);

    const syncStatusStore = useSyncStatusStore();
    // add the entity to modified tables if not synced
    if (event.entity.isSynced !== undefined && event.entity.isSynced === false) {
      syncStatusStore.addModifiedTable(event.metadata.tableName);
      // broadcast event to sync
      syncStatusStore.queueNextSyncPush();
    }

    // set last activity time
    setItem(config.localStorageKeyNames.lastActivityAt, Date.now());
  }


  /**
   * Called before entity update.
   */
  beforeUpdate(event: UpdateEvent<any>) {
    if (config.isDev && config.logging) console.log(`BEFORE ENTITY UPDATED: `, event.entity);

    // set database use status
    const dbConnectionStore = useDBConnectionStore();
    dbConnectionStore.setInUse(true); 
  }


  /**
   * Called after entity update.
   */
  async afterUpdate(event: UpdateEvent<any>) {
    if (config.isDev && config.logging) console.log(`AFTER ENTITY UPDATED: `, event.entity);

    const syncStatusStore = useSyncStatusStore();
    // add the entity to modified tables if not synced
    if (event.entity && event.entity.isSynced !== undefined && event.entity.isSynced === false) {
      syncStatusStore.addModifiedTable(event.metadata.tableName);
      // broadcast event to sync
      syncStatusStore.queueNextSyncPush();
    }

    // set database use status
    const dbConnectionStore = useDBConnectionStore();
    dbConnectionStore.setInUse(false);

    // set last activity time
    await setItem(config.localStorageKeyNames.lastActivityAt, Date.now());
  }


  /**
   * Called before entity removal.
   */
  beforeRemove(event: RemoveEvent<any>) {
    if (config.isDev && config.logging) console.log(`BEFORE ENTITY REMOVED: `, event.entity);

    // set database use status
    const dbConnectionStore = useDBConnectionStore();
    dbConnectionStore.setInUse(true);
  }


  /**
   * Called after entity removal.
   */
  async afterRemove(event: RemoveEvent<any>) {
    if (event.entityId) {
      if (config.isDev && config.logging) console.log(`AFTER ENTITY WITH ID ${event.entityId} REMOVED: `, event.entity);

      // save entity to deleted table logs
      const dbConnection: any = event.connection; // using the existing connection
      const deletedRecord = new DeletedRecord();
      deletedRecord.id = getID();
      deletedRecord.business_id = event.entity.business_id;
      deletedRecord.location_id = event.entity.location_id;
      deletedRecord.deleted_id = event.entityId;
      deletedRecord.table_name = event.metadata.tableName;
      deletedRecord.values = JSON.stringify(event.databaseEntity); // event.entity but event.databaseEntity includes 'id'
      deletedRecord.created_at = new Date();
      deletedRecord.updated_at = new Date();
      deletedRecord.last_modified = Date.now();
      deletedRecord.isSynced = false;
      await dbConnection.getRepository(DeletedRecord).save(deletedRecord);

      const syncStatusStore = useSyncStatusStore();
      // add the entity to modified tables if not synced
      if (event.entity && event.entity.isSynced !== undefined && event.entity.isSynced === false) {
        syncStatusStore.addModifiedTable(dbConnection.getMetadata(DeletedRecord).tableName);
        // broadcast event to sync
        syncStatusStore.queueNextSyncPush();
      }

      // set database use status
      const dbConnectionStore = useDBConnectionStore();
      dbConnectionStore.setInUse(false);
    }
    // set last activity time
    setItem(config.localStorageKeyNames.lastActivityAt, Date.now());
  }


  /**
   * Called after transaction commit.
   */
  async afterTransactionCommit(event: TransactionCommitEvent) {
     if (config.isDev && config.logging) console.log(`AFTER TRANSACTION COMMITTED: `, event);

    // For WEB platform, this is to save the database to web store to prevent loss of data
    saveToWebStore();
  }


  /**
   * Called after transaction rollback.
   */
  afterTransactionRollback(event: TransactionRollbackEvent) {
    if (config.isDev && config.logging) console.log(`AFTER TRANSACTION ROLLBACK: `, event);

    // For WEB platform, this is to save the database to web store to prevent loss of data
    saveToWebStore();
  }

}