import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm";

@Entity({ name: 'laundry_orders' })
@Index(['business_id', 'location_id', 'id'])
@Index(['business_id', 'location_id', 'last_modified'])
@Index(['business_id', 'location_id', 'isSynced'])
@Index(['business_id', 'location_id', 'code'])
export class LaundryOrder extends BaseEntity {
  @PrimaryColumn()
  id: string;

  @Column() 
  @Index()
  business_id: string;

  @Column() 
  @Index()
  location_id: string;

  @Column({ nullable: true })
  @Index()
  operator_id: string;

  @Column({ nullable: true })
  @Index()
  customer_id: string;

  @Column() 
  @Index()
  code: string;

  @Column({ type: 'decimal', precision: 26, scale: 8, default: 0.0 })
  subtotal: string;

  @Column({ type: 'decimal', precision: 26, scale: 8, default: 0.0 })
  tax: string;

  @Column({ type: 'decimal', precision: 26, scale: 8, default: 0.0 })
  discount: string;

  @Column({ type: 'decimal', precision: 26, scale: 8, default: 0.0 })
  total: string;

  @Column({ type: 'decimal', precision: 26, scale: 8, default: 0.0 })
  paid_amount: string;

  @Column({ default: 'unpaid' })
  payment_status: string;

  @Column({ default: 'cash' })
  payment_method: string;

  @Column({ type: 'text', nullable: true })
  notes: string;

  @Column({ default: 'pending' })
  status: string;

  @Column({ nullable: true })
  scheduled_delivery_at: Date;

  @Column({ nullable: true })
  delivered_at: Date;

  @Column({ type: 'text', nullable: true })
  metadata: string;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;
}