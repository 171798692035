import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm";

@Entity({ name: 'audits' })
@Index(['business_id', 'location_id', 'last_modified'])
@Index(['business_id', 'location_id', 'isSynced'])
@Index(['business_id', 'location_id', 'id'])
@Index(['business_id', 'location_id', 'auditable_type', 'auditable_id', 'last_modified'])
export class Audit extends BaseEntity {
  @PrimaryColumn()
  id: string;

  @Column() 
  @Index()
  business_id: string;

  @Column() 
  @Index()
  location_id: string;

  @Column() 
  auditable_type: string;

  @Column() 
  auditable_id: string;

  @Column() 
  userable_type: string;

  @Column() 
  userable_id: string;

  @Column() 
  event: string;

  @Column({ type: 'text', nullable: true })
  old_values: string;

  @Column({ type: 'text', nullable: true })
  new_values: string;

  @Column({ nullable: true }) 
  url: string;

  @Column({ nullable: true }) 
  ip: string;

  @Column({ type: 'text', nullable: true })
  user_agent: string;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;
}
