import { computed } from 'vue';
import router from "@/router"; 
import { useAuthStore } from '@/stores/authStore';
import { createAcl, defineAclRules } from 'vue-simple-acl/src';


// const user = computed(() => authStore.operator);
// const userPermissions = computed(() => authStore.operatorPermissions);

const user = computed(() => {
  const authStore = useAuthStore();
  return authStore.operator;
});

const userPermissions = computed(() => {
  const authStore = useAuthStore();
  return authStore.operatorPermissions;
});


const hasPermission = (user: any, permission: any, includesOwnerOrAdmin = true) => {
  // initialize and load store state data
  if (includesOwnerOrAdmin) {
    return user.id && (userPermissions.value?.includes('user-role::owner') || userPermissions.value?.includes('user-role::admin') || userPermissions.value?.includes(permission));
  } else {
    return user.id && userPermissions.value?.includes(permission);
  }
}


// Define the ACL Rules
const rules = () => defineAclRules((setRule) => {
  // ANY RULES
  setRule('any', () => true);
  // ROLE RULES
  setRule('user-role::owner', (user) => hasPermission(user, 'user-role::owner', false));
  setRule('user-role::admin', (user) => hasPermission(user, 'user-role::admin', false));
  setRule('user-role::user', (user) => hasPermission(user, 'user-role::user', false));
  // PERMISSION RULES
  // user permissions
  setRule('user.view', (user) => hasPermission(user, 'user.view', false));
  setRule('user.create', (user) => hasPermission(user, 'user.create', false));
  setRule('user.edit', (user) => hasPermission(user, 'user.edit', false));
  setRule('user.delete', (user) => hasPermission(user, 'user.delete, false'));
  // role permissions
  setRule('role.view', (user) => hasPermission(user, 'role.view', false));
  setRule('role.create', (user) => hasPermission(user, 'role.create', false));
  setRule('role.edit', (user) => hasPermission(user, 'role.edit', false));
  setRule('role.delete', (user) => hasPermission(user, 'role.delete', false));
  // device permissions
  setRule('device.view', (user) => hasPermission(user, 'device.view'));
  setRule('device.create', (user) => hasPermission(user, 'device.create'));
  setRule('device.edit', (user) => hasPermission(user, 'device.edit'));
  setRule('device.delete', (user) => hasPermission(user, 'device.delete'));
  // customer permissions
  setRule('customer.view', (user) => hasPermission(user, 'customer.view'));
  setRule('customer.create', (user) => hasPermission(user, 'customer.create'));
  setRule('customer.edit', (user) => hasPermission(user, 'customer.edit'));
  setRule('customer.delete', (user) => hasPermission(user, 'customer.delete'));
  // reservation permissions
  setRule('reservation.view', (user) => hasPermission(user, 'reservation.view'));
  setRule('reservation.create', (user) => hasPermission(user, 'reservation.create'));
  setRule('reservation.edit', (user) => hasPermission(user, 'reservation.edit'));
  setRule('reservation.delete', (user) => hasPermission(user, 'reservation.delete'));
  setRule('reservation.facilities.view', (user) => hasPermission(user, 'reservation.facilities.view'));
  setRule('reservation.facilities.create', (user) => hasPermission(user, 'reservation.facilities.create'));
  setRule('reservation.facilities.edit', (user) => hasPermission(user, 'reservation.facilities.edit'));
  setRule('reservation.facilities.delete', (user) => hasPermission(user, 'reservation.facilities.delete'));
  setRule('reservation.categories.view', (user) => hasPermission(user, 'reservation.categories.view'));
  setRule('reservation.categories.create', (user) => hasPermission(user, 'reservation.categories.create'));
  setRule('reservation.categories.edit', (user) => hasPermission(user, 'reservation.categories.edit'));
  setRule('reservation.categories.delete', (user) => hasPermission(user, 'reservation.categories.delete'));
  setRule('reservation.house-keeping.view', (user) => hasPermission(user, 'reservation.house-keeping.view'));
  setRule('reservation.house-keeping.edit', (user) => hasPermission(user, 'reservation.house-keeping.edit'));
  // expense permissions
  setRule('expense.view', (user) => hasPermission(user, 'expense.view'));
  setRule('expense.create', (user) => hasPermission(user, 'expense.create'));
  setRule('expense.edit', (user) => hasPermission(user, 'expense.edit'));
  setRule('expense.delete', (user) => hasPermission(user, 'expense.delete'));
  setRule('expense.categories.view', (user) => hasPermission(user, 'expense.categories.view'));
  setRule('expense.categories.create', (user) => hasPermission(user, 'expense.categories.create'));
  setRule('expense.categories.edit', (user) => hasPermission(user, 'expense.categories.edit'));
  setRule('expense.categories.delete', (user) => hasPermission(user, 'expense.categories.delete'));
  // income permissions
  setRule('income.view', (user) => hasPermission(user, 'income.view'));
  setRule('income.create', (user) => hasPermission(user, 'income.create'));
  setRule('income.edit', (user) => hasPermission(user, 'income.edit'));
  setRule('income.delete', (user) => hasPermission(user, 'income.delete'));
  setRule('income.categories.view', (user) => hasPermission(user, 'income.categories.view'));
  setRule('income.categories.create', (user) => hasPermission(user, 'income.categories.create'));
  setRule('income.categories.edit', (user) => hasPermission(user, 'income.categories.edit'));
  setRule('income.categories.delete', (user) => hasPermission(user, 'income.categories.delete'));
  // inventory permissions
  setRule('inventory.items.view', (user) => hasPermission(user, 'inventory.items.view'));
  setRule('inventory.items.create', (user) => hasPermission(user, 'inventory.items.create'));
  setRule('inventory.items.edit', (user) => hasPermission(user, 'inventory.items.edit'));
  setRule('inventory.items.delete', (user) => hasPermission(user, 'inventory.items.delete'));
  setRule('inventory.categories.view', (user) => hasPermission(user, 'inventory.categories.view'));
  setRule('inventory.categories.create', (user) => hasPermission(user, 'inventory.categories.create'));
  setRule('inventory.categories.edit', (user) => hasPermission(user, 'inventory.categories.edit'));
  setRule('inventory.categories.delete', (user) => hasPermission(user, 'inventory.categories.delete'));
  setRule('inventory.suppliers.view', (user) => hasPermission(user, 'inventory.suppliers.view'));
  setRule('inventory.suppliers.create', (user) => hasPermission(user, 'inventory.suppliers.create'));
  setRule('inventory.suppliers.edit', (user) => hasPermission(user, 'inventory.suppliers.edit'));
  setRule('inventory.suppliers.delete', (user) => hasPermission(user, 'inventory.suppliers.delete'));
  setRule('inventory.locations.view', (user) => hasPermission(user, 'inventory.locations.view'));
  setRule('inventory.locations.create', (user) => hasPermission(user, 'inventory.locations.create'));
  setRule('inventory.locations.edit', (user) => hasPermission(user, 'inventory.locations.edit'));
  setRule('inventory.locations.delete', (user) => hasPermission(user, 'inventory.locations.delete'));
  setRule('inventory.purchase-orders.view', (user) => hasPermission(user, 'inventory.purchase-orders.view'));
  setRule('inventory.purchase-orders.create', (user) => hasPermission(user, 'inventory.purchase-orders.create'));
  setRule('inventory.purchase-orders.edit', (user) => hasPermission(user, 'inventory.purchase-orders.edit'));
  setRule('inventory.purchase-orders.delete', (user) => hasPermission(user, 'inventory.purchase-orders.delete'));
  setRule('inventory.stock-adjustments.manage', (user) => hasPermission(user, 'inventory.stock-adjustments.manage'));
  // booking-order permissions
  setRule('booking-order.view', (user) => hasPermission(user, 'booking-order.view'));
  setRule('booking-order.create', (user) => hasPermission(user, 'booking-order.create'));
  setRule('booking-order.edit', (user) => hasPermission(user, 'booking-order.edit'));
  setRule('booking-order.delete', (user) => hasPermission(user, 'booking-order.delete'));
  setRule('booking-order.change-stock-location', (user) => hasPermission(user, 'booking-order.change-stock-location'));
  // sales permissions
  setRule('sales.view', (user) => hasPermission(user, 'sales.view'));
  setRule('sales.create', (user) => hasPermission(user, 'sales.create'));
  setRule('sales.edit', (user) => hasPermission(user, 'sales.edit'));
  setRule('sales.delete', (user) => hasPermission(user, 'sales.delete'));
  setRule('sales.change-stock-location', (user) => hasPermission(user, 'sales.change-stock-location'));
  // laundry permissions
  setRule('laundry.view', (user) => hasPermission(user, 'laundry.view'));
  setRule('laundry.create', (user) => hasPermission(user, 'laundry.create'));
  setRule('laundry.edit', (user) => hasPermission(user, 'laundry.edit'));
  setRule('laundry.delete', (user) => hasPermission(user, 'laundry.delete'));
  setRule('laundry.service-lists.view', (user) => hasPermission(user, 'laundry.service-lists.view'));
  setRule('laundry.service-lists.create', (user) => hasPermission(user, 'laundry.service-lists.create'));
  setRule('laundry.service-lists.edit', (user) => hasPermission(user, 'laundry.service-lists.edit'));
  setRule('laundry.service-lists.delete', (user) => hasPermission(user, 'laundry.service-lists.delete'));
  // pool permissions
  setRule('pool.view', (user) => hasPermission(user, 'pool.view'));
  setRule('pool.create', (user) => hasPermission(user, 'pool.create'));
  setRule('pool.edit', (user) => hasPermission(user, 'pool.edit'));
  setRule('pool.delete', (user) => hasPermission(user, 'pool.delete'));
  setRule('pool.catalogues.view', (user) => hasPermission(user, 'pool.catalogues.view'));
  setRule('pool.catalogues.create', (user) => hasPermission(user, 'pool.catalogues.create'));
  setRule('pool.catalogues.edit', (user) => hasPermission(user, 'pool.catalogues.edit'));
  setRule('pool.catalogues.delete', (user) => hasPermission(user, 'pool.catalogues.delete'));
  // parking-ticket permissions
  setRule('parking-ticket.view', (user) => hasPermission(user, 'parking-ticket.view'));
  setRule('parking-ticket.create', (user) => hasPermission(user, 'parking-ticket.create'));
  setRule('parking-ticket.edit', (user) => hasPermission(user, 'parking-ticket.edit'));
  setRule('parking-ticket.delete', (user) => hasPermission(user, 'parking-ticket.delete'));
  // 
});


const simpleAcl = createAcl({
  user, // short for user: user
  rules, // short for rules: rules
  router, // OPTIONAL, short for router: router 
  // other optional vue-simple-acl options here... See Vue Simple ACL Options below
  onDeniedRoute: '/unauthorized', // OPTIONAL, short for onDeniedRoute: '/denied'
});

export default simpleAcl;