import { defineStore, acceptHMRUpdate } from 'pinia';
// import { useDBConnectionStore } from '@/stores/dbConnectionStore';
// import { watch, computed } from 'vue';
import { getID } from '@/utils/unique-id';
// import { sleep } from '@/utils/helpers';
import config from '@/config';
// import { setItem } from '@/utils/storage';

interface QueueItem {
  id: string;
  job: any;
  timestamp: number;
}

interface State {
  queue: Array<QueueItem>;
  activeQueueItem: QueueItem | null;
  inProgress: boolean;

}

export const useQueueStore = defineStore('queue', {

  state: (): State => ({ 
    queue: [],
    activeQueueItem: null,
    inProgress: false,
  }),

  getters: {
    //
  },

  actions: {
    async addToQueue(job: any, id: string | null = null, immediate = false) {
      return new Promise((resolve, reject) => {
        if (immediate) {
          try {
            this.inProgress = true;
            if (typeof job === 'function') {
              // wait and process while db not in use
              job()
              .then()
              .catch()
              .finally(() => {        
                this.inProgress = false;
                resolve(true);
              });
            }
          } catch (error) {
            this.inProgress = false;
            reject(error);
          }
        } else {
          // check if job with the id exists in queue already and remove it, this is useful to avoid queueing the same job multiple times
          if (id) {
            const queue = this.queue;
            const queueItemIndex = queue.findIndex(item => item.id === id);
            if (queueItemIndex > -1) {
              queue.splice(queueItemIndex, 1);
            }
          }
          // add job to queue
          const timestamp = Date.now();
          if (!id) {
            id = getID() + '-' + timestamp;
          }
          this.queue.push({
            id: id,
            job,
            timestamp,
          });
          resolve(true);
        }
      });
    },
    removeFromQueue(id: string) {
      this.queue = this.queue.filter(item => item.id !== id);
    },
    setActiveQueueItem(id: string) {
      const item = this.queue.find(item => item.id === id);
      if (item) {
        this.activeQueueItem = item;
      }
    },
    resetActiveQueueItem() {
      this.activeQueueItem = null;
    },

    getNextQueueItem() {
      if (this.inProgress === false) {
        if (!this.activeQueueItem && this.queue.length > 0) {
          const item = this.queue.shift();
          if (item) {
            this.activeQueueItem = item;
          }
        }
      }
    },
    
    async processQueueItemJob() {
      try {
        // const dbConnectionStore = useDBConnectionStore();

        if (this.activeQueueItem && this.inProgress === false) {
          this.inProgress = true;
          // await sleep(200);
          if (typeof this.activeQueueItem.job === 'function') {
            // wait and process while db not in use
            // while (dbConnectionStore.inUse === true) {
            //   await sleep(100);
            // }
            await this.activeQueueItem.job();
          }
          this.resetActiveQueueItem();
          this.inProgress = false;
        }
      } catch (error) {
        if (config.isDev) console.log(error);
        this.inProgress = false;
      }
    },    

    async startQueueService() {
      await startQueueService();
    }
  }
});


const startQueueService = async () => {
  const queueStore = useQueueStore();
  // // watch activeQueueItem and process job
  // const activeQueueItem = computed(() => queueStore.activeQueueItem);
  // watch(activeQueueItem, async () => {
  //   if (activeQueueItem.value) {
  //     await queueStore.processQueueItemJob();
  //   } else {
  //     queueStore.getNextQueueItem();
  //     queueStore.processQueueItemJob();
  //   }
  //   console.log('startQueueService: activeQueueItem changed :::', activeQueueItem.value);
  // });
  
  // // watch queue and queue next item
  // const queue = computed(() => queueStore.queue);
  // watch(queue, async () => {
  //   if (queue.value.length > 0) {
  //     queueStore.getNextQueueItem();
  //   }
  //   console.log('startQueueService: queue changed :::', queue.value);
  // });

  queueStore.$subscribe(async ()  => { // (mutation, state)
    if (queueStore.inProgress === false && queueStore.activeQueueItem === null) {
      // process queue item
      queueStore.getNextQueueItem();
      queueStore.processQueueItemJob();
    } else {
      if (queueStore.inProgress === false)  {
        setTimeout(() => {
          queueStore.processQueueItemJob();
        }, 5000);
      }
    }
    // persist the whole queue store state to the local storage whenever it changes
    // await setItem(config.localStorageKeyNames.queue, state);
  }, { detached: true });
};



if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useQueueStore, import.meta.webpackHot));
}