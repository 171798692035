import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { SalesOrder } from '@/entity/SalesOrder';

export const mergeSalesOrders = async (businessId: any, locationId: any, salesOrders: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching sales orders data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (salesOrders && salesOrders.length > 0) {
    for (const salesOrder of salesOrders) {
      let salesOrderEntity = await dbConnection
        .getRepository(SalesOrder)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: salesOrder.id })
        .getOne();
      let insertMode = false;
      if (!salesOrderEntity) {
        insertMode = true;
        salesOrderEntity = new SalesOrder();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && salesOrderEntity.last_modified < salesOrder.last_modified)) {
        salesOrderEntity.id = salesOrder.id;
        salesOrderEntity.business_id = salesOrder.business_id;
        salesOrderEntity.location_id = salesOrder.location_id;
        salesOrderEntity.operator_id = salesOrder.operator_id;
        salesOrderEntity.inventory_location_id = salesOrder.inventory_location_id;
        salesOrderEntity.customer_id = salesOrder.customer_id;
        salesOrderEntity.code = salesOrder.code;
        salesOrderEntity.subtotal = salesOrder.subtotal;
        salesOrderEntity.tax = salesOrder.tax;
        salesOrderEntity.discount = salesOrder.discount;
        salesOrderEntity.total = salesOrder.total;
        salesOrderEntity.paid_amount = salesOrder.paid_amount;
        salesOrderEntity.payment_status = salesOrder.payment_status;
        salesOrderEntity.payment_method = salesOrder.payment_method;
        salesOrderEntity.notes = salesOrder.notes;
        salesOrderEntity.status = salesOrder.status;
        salesOrderEntity.metadata = salesOrder.metadata ? JSON.stringify(salesOrder.metadata) : null;
        salesOrderEntity.created_at = salesOrder.created_at;
        salesOrderEntity.updated_at = salesOrder.updated_at;
        salesOrderEntity.last_modified = salesOrder.last_modified;
        salesOrderEntity.isSynced = true;
        await dbConnection.getRepository(SalesOrder).save(salesOrderEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedSalesOrders = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const salesOrders = await dbConnection
    .getRepository(SalesOrder)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return salesOrders.length > 0 ? salesOrders : null;
}