import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { BookingOrderItem } from '@/entity/BookingOrderItem';

export const mergeBookingOrderItems = async (businessId: any, locationId: any, bookingOrderItems: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching booking order items data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (bookingOrderItems && bookingOrderItems.length > 0) {
    for (const bookingOrderItem of bookingOrderItems) {
      let bookingOrderItemEntity = await dbConnection
        .getRepository(BookingOrderItem)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: bookingOrderItem.id })
        .getOne();
      let insertMode = false;
      if (!bookingOrderItemEntity) {
        insertMode = true;
        bookingOrderItemEntity = new BookingOrderItem();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && bookingOrderItemEntity.last_modified < bookingOrderItem.last_modified)) {
        bookingOrderItemEntity.id = bookingOrderItem.id;
        bookingOrderItemEntity.business_id = bookingOrderItem.business_id;
        bookingOrderItemEntity.location_id = bookingOrderItem.location_id;
        bookingOrderItemEntity.booking_order_id = bookingOrderItem.booking_order_id;
        bookingOrderItemEntity.inventory_item_id = bookingOrderItem.inventory_item_id;
        bookingOrderItemEntity.quantity = bookingOrderItem.quantity;
        bookingOrderItemEntity.price = bookingOrderItem.price;
        bookingOrderItemEntity.metadata = bookingOrderItem.metadata ? JSON.stringify(bookingOrderItem.metadata) : null;
        bookingOrderItemEntity.created_at = bookingOrderItem.created_at;
        bookingOrderItemEntity.updated_at = bookingOrderItem.updated_at;
        bookingOrderItemEntity.last_modified = bookingOrderItem.last_modified;
        bookingOrderItemEntity.isSynced = true;
        await dbConnection.getRepository(BookingOrderItem).save(bookingOrderItemEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedBookingOrderItems = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const bookingOrderItems = await dbConnection
    .getRepository(BookingOrderItem)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return bookingOrderItems.length > 0 ? bookingOrderItems : null;
}