import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { InventoryLocation } from '@/entity/InventoryLocation';

export const mergeInventoryLocations = async (businessId: any, locationId: any, inventoryLocations: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching inventory locations data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (inventoryLocations && inventoryLocations.length > 0) {
    for (const inventoryLocation of inventoryLocations) {
      let inventoryLocationEntity = await dbConnection
        .getRepository(InventoryLocation)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: inventoryLocation.id })
        .getOne();
      let insertMode = false;
      if (!inventoryLocationEntity) {
        insertMode = true;
        inventoryLocationEntity = new InventoryLocation();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && inventoryLocationEntity.last_modified < inventoryLocation.last_modified)) {
        inventoryLocationEntity.id = inventoryLocation.id;
        inventoryLocationEntity.business_id = inventoryLocation.business_id;
        inventoryLocationEntity.location_id = inventoryLocation.location_id;
        inventoryLocationEntity.name = inventoryLocation.name;
        inventoryLocationEntity.description = inventoryLocation.description;
        inventoryLocationEntity.is_enabled = inventoryLocation.is_enabled;
        inventoryLocationEntity.metadata = inventoryLocation.metadata ? JSON.stringify(inventoryLocation.metadata) : null;
        inventoryLocationEntity.created_at = inventoryLocation.created_at;
        inventoryLocationEntity.updated_at = inventoryLocation.updated_at;
        inventoryLocationEntity.last_modified = inventoryLocation.last_modified;
        inventoryLocationEntity.isSynced = true;
        await dbConnection.getRepository(InventoryLocation).save(inventoryLocationEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedInventoryLocations = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const inventoryLocations = await dbConnection
    .getRepository(InventoryLocation)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return inventoryLocations.length > 0 ? inventoryLocations : null;
}