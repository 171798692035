import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { Income } from '@/entity/Income';

export const mergeIncomes = async (businessId: any, locationId: any, incomes: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching income data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (incomes && incomes.length > 0) {
    for (const income of incomes) {
      let incomeEntity = await dbConnection
        .getRepository(Income)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: income.id })
        .getOne();
      let insertMode = false;
      if (!incomeEntity) {
        insertMode = true;
        incomeEntity = new Income();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && incomeEntity.last_modified < income.last_modified)) {
        incomeEntity.id = income.id;
        incomeEntity.business_id = income.business_id;
        incomeEntity.location_id = income.location_id;
        incomeEntity.operator_id = income.operator_id;
        incomeEntity.amount = income.amount;
        incomeEntity.received_at = income.received_at;
        incomeEntity.income_category_id = income.income_category_id;
        incomeEntity.notes = income.notes;
        incomeEntity.created_at = income.created_at;
        incomeEntity.updated_at = income.updated_at;
        incomeEntity.last_modified = income.last_modified;
        incomeEntity.isSynced = true;
        await dbConnection.getRepository(Income).save(incomeEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedIncomes = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const incomes = await dbConnection
    .getRepository(Income)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return incomes.length > 0 ? incomes : null;
}