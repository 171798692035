import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { SalesOrderItem } from '@/entity/SalesOrderItem';

export const mergeSalesOrderItems = async (businessId: any, locationId: any, salesOrderItems: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching sales order items data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (salesOrderItems && salesOrderItems.length > 0) {
    for (const salesOrderItem of salesOrderItems) {
      let salesOrderItemEntity = await dbConnection
        .getRepository(SalesOrderItem)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: salesOrderItem.id })
        .getOne();
      let insertMode = false;
      if (!salesOrderItemEntity) {
        insertMode = true;
        salesOrderItemEntity = new SalesOrderItem();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && salesOrderItemEntity.last_modified < salesOrderItem.last_modified)) {
        salesOrderItemEntity.id = salesOrderItem.id;
        salesOrderItemEntity.business_id = salesOrderItem.business_id;
        salesOrderItemEntity.location_id = salesOrderItem.location_id;
        salesOrderItemEntity.sales_order_id = salesOrderItem.sales_order_id;
        salesOrderItemEntity.inventory_item_id = salesOrderItem.inventory_item_id;
        salesOrderItemEntity.quantity = salesOrderItem.quantity;
        salesOrderItemEntity.price = salesOrderItem.price;
        salesOrderItemEntity.metadata = salesOrderItem.metadata ? JSON.stringify(salesOrderItem.metadata) : null;
        salesOrderItemEntity.created_at = salesOrderItem.created_at;
        salesOrderItemEntity.updated_at = salesOrderItem.updated_at;
        salesOrderItemEntity.last_modified = salesOrderItem.last_modified;
        salesOrderItemEntity.isSynced = true;
        await dbConnection.getRepository(SalesOrderItem).save(salesOrderItemEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedSalesOrderItems = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const salesOrderItems = await dbConnection
    .getRepository(SalesOrderItem)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return salesOrderItems.length > 0 ? salesOrderItems : null;
}