import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { InventorySupplier } from '@/entity/InventorySupplier';

export const mergeInventorySuppliers = async (businessId: any, locationId: any, inventorySuppliers: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching inventory suppliers data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (inventorySuppliers && inventorySuppliers.length > 0) {
    for (const inventorySupplier of inventorySuppliers) {
      let inventorySupplierEntity = await dbConnection
        .getRepository(InventorySupplier)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: inventorySupplier.id })
        .getOne();
      let insertMode = false;
      if (!inventorySupplierEntity) {
        insertMode = true;
        inventorySupplierEntity = new InventorySupplier();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && inventorySupplierEntity.last_modified < inventorySupplier.last_modified)) {
        inventorySupplierEntity.id = inventorySupplier.id;
        inventorySupplierEntity.business_id = inventorySupplier.business_id;
        inventorySupplierEntity.location_id = inventorySupplier.location_id;
        inventorySupplierEntity.name = inventorySupplier.name;
        inventorySupplierEntity.email = inventorySupplier.email;
        inventorySupplierEntity.phone = inventorySupplier.phone;
        inventorySupplierEntity.address = inventorySupplier.address;
        inventorySupplierEntity.metadata = inventorySupplier.metadata ? JSON.stringify(inventorySupplier.metadata) : null;
        inventorySupplierEntity.created_at = inventorySupplier.created_at;
        inventorySupplierEntity.updated_at = inventorySupplier.updated_at;
        inventorySupplierEntity.last_modified = inventorySupplier.last_modified;
        inventorySupplierEntity.isSynced = true;
        await dbConnection.getRepository(InventorySupplier).save(inventorySupplierEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedInventorySuppliers = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const inventorySuppliers = await dbConnection
    .getRepository(InventorySupplier)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return inventorySuppliers.length > 0 ? inventorySuppliers : null;
}