import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { InventoryStockAdjustmentItem } from '@/entity/InventoryStockAdjustmentItem';

export const mergeInventoryStockAdjustmentItems = async (businessId: any, locationId: any, inventoryStockAdjustmentItems: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching inventory stock adjustment items data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (inventoryStockAdjustmentItems && inventoryStockAdjustmentItems.length > 0) {
    for (const inventoryStockAdjustmentItem of inventoryStockAdjustmentItems) {
      let inventoryStockAdjustmentItemEntity = await dbConnection
        .getRepository(InventoryStockAdjustmentItem)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: inventoryStockAdjustmentItem.id })
        .getOne();
      let insertMode = false;
      if (!inventoryStockAdjustmentItemEntity) {
        insertMode = true;
        inventoryStockAdjustmentItemEntity = new InventoryStockAdjustmentItem();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && inventoryStockAdjustmentItemEntity.last_modified < inventoryStockAdjustmentItem.last_modified)) {
        inventoryStockAdjustmentItemEntity.id = inventoryStockAdjustmentItem.id;
        inventoryStockAdjustmentItemEntity.business_id = inventoryStockAdjustmentItem.business_id;
        inventoryStockAdjustmentItemEntity.location_id = inventoryStockAdjustmentItem.location_id;
        inventoryStockAdjustmentItemEntity.inventory_stock_adjustment_id = inventoryStockAdjustmentItem.inventory_stock_adjustment_id;
        inventoryStockAdjustmentItemEntity.inventory_item_id = inventoryStockAdjustmentItem.inventory_item_id;
        inventoryStockAdjustmentItemEntity.quantity = inventoryStockAdjustmentItem.quantity;
        inventoryStockAdjustmentItemEntity.quantity_after = inventoryStockAdjustmentItem.quantity_after;
        inventoryStockAdjustmentItemEntity.cost_price = inventoryStockAdjustmentItem.cost_price;
        inventoryStockAdjustmentItemEntity.cost_price_before = inventoryStockAdjustmentItem.cost_price_before;
        inventoryStockAdjustmentItemEntity.metadata = inventoryStockAdjustmentItem.metadata ? JSON.stringify(inventoryStockAdjustmentItem.metadata) : null;
        inventoryStockAdjustmentItemEntity.created_at = inventoryStockAdjustmentItem.created_at;
        inventoryStockAdjustmentItemEntity.updated_at = inventoryStockAdjustmentItem.updated_at;
        inventoryStockAdjustmentItemEntity.last_modified = inventoryStockAdjustmentItem.last_modified;
        inventoryStockAdjustmentItemEntity.isSynced = true;
        await dbConnection.getRepository(InventoryStockAdjustmentItem).save(inventoryStockAdjustmentItemEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedInventoryStockAdjustmentItems = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const inventoryStockAdjustmentItems = await dbConnection
    .getRepository(InventoryStockAdjustmentItem)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return inventoryStockAdjustmentItems.length > 0 ? inventoryStockAdjustmentItems : null;
}