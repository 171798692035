import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { Location } from '@/entity/Location';

export const mergeLocation = async (businessId: any, location: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching location data... Please wait.');

  const dbConnection: any = await getDBConnection();
  let locationEntity = await dbConnection
    .getRepository(Location)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(id) = LOWER(:id)', { id: location.id })
    .getOne();
  let insertMode = false;
  if (!locationEntity) {
    insertMode = true;
    locationEntity = new Location();
  }
  // insert new or compare last modified time and update most recent
  if (insertMode === true || (insertMode === false && locationEntity.last_modified < location.last_modified)) {
    locationEntity.id = location.id;
    locationEntity.business_id = location.business_id;
    locationEntity.name = location.name;
    locationEntity.address = location.address;
    locationEntity.city = location.city;
    locationEntity.state = location.state;
    locationEntity.country = location.country;
    locationEntity.timezone = location.timezone;
    locationEntity.status = location.status;
    locationEntity.metadata = location.metadata ? JSON.stringify(location.metadata) : null;
    locationEntity.created_at = location.created_at;
    locationEntity.updated_at = location.updated_at;
    locationEntity.last_modified = location.last_modified;
    locationEntity.isSynced = true;
    await dbConnection.getRepository(Location).save(locationEntity);
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedLocation = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const location = await dbConnection
    .getRepository(Location)
    .createQueryBuilder()
    .andWhere('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .where('LOWER(id) = LOWER(:id)', { id: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getOne();
  return location;
}