import { defineStore, acceptHMRUpdate } from 'pinia';
import { watch } from 'vue';
import { getItem, setItem } from '@/utils/storage';
import config from '@/config';

interface State {
  syncPushTimer: number;
  syncPullTasks: number,
  syncPullTaskCompleted: number,
  syncPullProgress: number,
  syncPullProgressMessage: string | null,
  syncPulled: boolean,
  modifiedTables: Array<any>;
}

export const useSyncStatusStore = defineStore('syncStatus', {

  state: (): State => ({ 
    syncPushTimer: 0,
    syncPullTasks: 0,
    syncPullTaskCompleted: 0,
    syncPullProgress: 0,
    syncPullProgressMessage: '',
    syncPulled: false,
    modifiedTables: [],
  }),

  getters: {
    //
  },

  actions: {
    queueNextSyncPush() {
      this.syncPushTimer = 2000; // 2s
    },
    resetSyncPushTimer() {
      this.syncPushTimer = 0;
    },
    startSyncPullTasks(tasks: number) {
      this.syncPullTasks = tasks;
      this.syncPullTaskCompleted = 0;
      this.syncPullProgressMessage = 'Initializing database...';
    },
    completeSyncPullProgress() {
      this.syncPullProgress = 100;
      this.syncPullProgressMessage = 'Database synching completed.';
    },
    updateSyncPullProgress() {
      this.syncPullTaskCompleted = this.syncPullTaskCompleted + 1;
      this.syncPullProgress = Math.floor((this.syncPullTaskCompleted / this.syncPullTasks) * 100);
    },
    updateSyncPullProgressMessage(message: string) {
      this.syncPullProgressMessage = message;
    },
    setSyncPulled(status: boolean) {
      this.syncPulled = status;
    },
    normalizeTableName(table: string) {
      if (table == 'users') table = 'user';
      if (table == 'businesses') table = 'business';
      if (table == 'locations') table = 'location';
      return table;
    },
    addModifiedTable(table: any) {
      this.loadStoredModifiedTables();
      table = this.normalizeTableName(table);
      if (!this.modifiedTables.includes(table)) {
        this.modifiedTables.push(table);
      }
      this.saveStoredModifiedTables();
    },
    removeModifiedTable(table: any) {
      this.loadStoredModifiedTables();
      table = this.normalizeTableName(table);
      const index = this.modifiedTables.findIndex(item => item === table);
      if (index > -1) {
        this.modifiedTables.splice(index, 1);
      }
      this.saveStoredModifiedTables();
    },
    isModifiedTable(table: any) {
      this.loadStoredModifiedTables();
      table = this.normalizeTableName(table);
      return this.modifiedTables.findIndex(item => item === table) > -1;
    },
    async loadStoredModifiedTables() {
      if (this.modifiedTables.length === 0) {
        const storedModifiedTables = await getItem(config.localStorageKeyNames.modifiedTables);
        if (storedModifiedTables && storedModifiedTables.length > 0) {
          this.modifiedTables = storedModifiedTables;
        }
      }
    },
    async saveStoredModifiedTables() {
      await setItem(config.localStorageKeyNames.modifiedTables, this.modifiedTables);
    },

    async afterSyncPulled(callback: any) {
      watch(() => this.syncPulled, async (newValue) => {
        if (newValue) {
          // if callback is a function, call it
          if (typeof callback === 'function') {
            await callback();
          }
          this.syncPulled = false;
        }
      });
    }
  }
});

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useSyncStatusStore, import.meta.webpackHot));
}

