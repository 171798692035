import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { Business } from '@/entity/Business';

export const mergeBusiness = async (business: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching business data... Please wait.');

  const dbConnection: any = await getDBConnection();
  let businessEntity = await dbConnection
    .getRepository(Business)
    .createQueryBuilder()
    .where('LOWER(id) = LOWER(:id)', { id: business.id })
    .getOne();
  let insertMode = false;
  if (!businessEntity) {
    insertMode = true;
    businessEntity = new Business();
  }
  // insert new or compare last modified time and update most recent
  if (insertMode === true || (insertMode === false && businessEntity.last_modified < business.last_modified)) {
    businessEntity.id = business.id;
    businessEntity.reseller_id = business.reseller_id;
    businessEntity.name = business.name;
    businessEntity.email = business.email;
    businessEntity.phone = business.phone;
    businessEntity.address = business.address;
    businessEntity.city = business.city;
    businessEntity.state = business.state;
    businessEntity.country = business.country;
    businessEntity.timezone = business.timezone;
    businessEntity.status = business.status;
    businessEntity.photo = business.photo;
    businessEntity.currency_code = business.currency_code;
    businessEntity.metadata = business.metadata ? JSON.stringify(business.metadata) : null;
    businessEntity.created_at = business.created_at;
    businessEntity.updated_at = business.updated_at;
    businessEntity.last_modified = business.last_modified;
    businessEntity.isSynced = true;
    await dbConnection.getRepository(Business).save(businessEntity);
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedBusiness = async (businessId: any) => {
  const dbConnection: any = await getDBConnection();
  const business = await dbConnection
    .getRepository(Business)
    .createQueryBuilder()
    .where('LOWER(id) = LOWER(:id)', { id: businessId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getOne();
  return business;
}