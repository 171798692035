import uuid from 'uuid-random';

/**
 * Generates a UUID v4.
 * @returns {string}
 */
export function getID(): string {
  return uuid();
}

/**
 * Generate Binary UUIDs
 * @returns {string}
 */
export function getBinID(): any {
  uuid.bin(); 
}

/**
 * Validate a UUID v4 String
 * @param id - id to be checked
 * @returns {boolean}
 */
export function isValidID(id: string): boolean {
  return uuid.test(id);
}


/**
 * Generate short unique invoice number without collisions and no guid nor uuid
 * @returns {string}
 */
export function getRandomCode(): string {
  const length = 9;
  const zeroFill = '0'.toString().repeat(length);
  return ((zeroFill + Math.random().toString(36).substr(2, length)).slice(-length)).toUpperCase();
}

/** 
 * Generate random integer number
 * @returns {number} random number
 */
export function getRandomNumber(min: number, max: number): number {
  // return Math.floor(Math.random() * max) + min;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

