import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { Expense } from '@/entity/Expense';

export const mergeExpenses = async (businessId: any, locationId: any, expenses: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching expenses data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (expenses && expenses.length > 0) {
    for (const expense of expenses) {
      let expenseEntity = await dbConnection
        .getRepository(Expense)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: expense.id })
        .getOne();
      let insertMode = false;
      if (!expenseEntity) {
        insertMode = true;
        expenseEntity = new Expense();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && expenseEntity.last_modified < expense.last_modified)) {
        expenseEntity.id = expense.id;
        expenseEntity.business_id = expense.business_id;
        expenseEntity.location_id = expense.location_id;
        expenseEntity.operator_id = expense.operator_id;
        expenseEntity.amount = expense.amount;
        expenseEntity.spent_at = expense.spent_at;
        expenseEntity.expense_category_id = expense.expense_category_id;
        expenseEntity.notes = expense.notes;
        expenseEntity.created_at = expense.created_at;
        expenseEntity.updated_at = expense.updated_at;
        expenseEntity.last_modified = expense.last_modified;
        expenseEntity.isSynced = true;
        await dbConnection.getRepository(Expense).save(expenseEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedExpenses = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const expenses = await dbConnection
    .getRepository(Expense)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return expenses.length > 0 ? expenses : null;
}