import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { Operator } from '@/entity/Operator';

export const mergeOperators = async (businessId: any, locationId: any, operators: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching operators data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (operators && operators.length > 0) {
    for (const operator of operators) {
      let operatorEntity = await dbConnection
        .getRepository(Operator)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: operator.id })
        .getOne();
      let insertMode = false;
      if (!operatorEntity) {
        insertMode = true;
        operatorEntity = new Operator();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && operatorEntity.last_modified < operator.last_modified)) {
        operatorEntity.id = operator.id;
        operatorEntity.business_id = operator.business_id;
        operatorEntity.location_id = operator.location_id;
        operatorEntity.username = operator.username;
        operatorEntity.pin = operator.pin;
        operatorEntity.is_user = operator.is_user;
        operatorEntity.name = operator.name;
        operatorEntity.email = operator.email;
        operatorEntity.phone = operator.phone;
        operatorEntity.operator_role_id = operator.operator_role_id;
        operatorEntity.status = operator.status;
        operatorEntity.photo = operator.photo;
        operatorEntity.metadata = operator.metadata ? JSON.stringify(operator.metadata) : null;
        operatorEntity.created_at = operator.created_at;
        operatorEntity.updated_at = operator.updated_at;
        operatorEntity.last_modified = operator.last_modified;
        operatorEntity.isSynced = true;
        await dbConnection.getRepository(Operator).save(operatorEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedOperators = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const operators = await dbConnection
    .getRepository(Operator)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return operators.length > 0 ? operators : null;
}