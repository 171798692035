import { Entity, Index, PrimaryColumn, Column, BaseEntity } from "typeorm";

@Entity({ name: 'operators' })
@Index(['business_id', 'location_id', 'id'])
@Index(['business_id', 'location_id', 'last_modified'])
@Index(['business_id', 'location_id', 'isSynced'])
export class Operator extends BaseEntity {
  @PrimaryColumn()
  id: string;
  
  @Column()
  @Index()
  business_id: string;

  @Column()
  @Index()
  location_id: string;

  @Column() 
  username: string;

  @Column() 
  pin: string;

  @Column({ default: false }) 
  is_user: boolean;

  @Column({ nullable: true }) 
  name: string;

  @Column({ nullable: true })
  email: string;

  @Column({ nullable: true }) 
  phone: string;

  @Column({ nullable: true })
  @Index()
  operator_role_id: string;

  @Column({ default: 'active' })
  status: string;

  @Column({ type: 'text', nullable: true })
  photo: string;

  @Column({ type: 'text', nullable: true })
  metadata: string;

  @Column()
  created_at: Date;

  @Column({ nullable: true })
  updated_at: Date;

  @Column({ type: 'bigint', nullable: true })
  @Index()
  last_modified: number;

  @Column({ default: false })
  @Index()
  isSynced: boolean;
}

            