import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { User } from '@/entity/User';

export const mergeUser = async (user: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching user data... Please wait.');

  const dbConnection: any = await getDBConnection();
  let userEntity = await dbConnection
    .getRepository(User)
    .createQueryBuilder()
    .where('LOWER(id) = LOWER(:id)', { id: user.id })
    .getOne();
  let insertMode = false;
  if (!userEntity) {
    insertMode = true;
    userEntity = new User();
  }
  // insert new or compare last modified time and update most recent
  if (insertMode === true || (insertMode === false && userEntity.last_modified <  new Date(user.updated_at).getTime())) {
    userEntity.id = user.id;
    userEntity.name = user.name;
    userEntity.email = user.email;
    userEntity.email_verified_at = user.email_verified_at;
    userEntity.phone = user.phone;
    userEntity.role = user.pivot?.role;
    userEntity.status = user.status;
    userEntity.photo = user.photo;
    userEntity.metadata = user.metadata ? JSON.stringify(user.metadata) : null;
    userEntity.created_at = user.created_at;
    userEntity.updated_at = user.updated_at;
    userEntity.last_modified = new Date(user.updated_at).getTime();
    userEntity.isSynced = true;
    await dbConnection.getRepository(User).save(userEntity);
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedUser = async (userId: any) => {
  const dbConnection: any = await getDBConnection();
  const user = await dbConnection
    .getRepository(User)
    .createQueryBuilder()
    .where('LOWER(id) = LOWER(:id)', { id: userId })
    // .andWhere('#last_modified > :lastSyncPushedAt', { lastSyncPushedAt })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getOne();
  return user;
}