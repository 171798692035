import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { ParkingTicket } from '@/entity/ParkingTicket';

export const mergeParkingTickets = async (businessId: any, locationId: any, parkingTickets: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching parking tickets data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (parkingTickets && parkingTickets.length > 0) {
    for (const parkingTicket of parkingTickets) {
      let parkingTicketEntity = await dbConnection
        .getRepository(ParkingTicket)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: parkingTicket.id })
        .getOne();
      let insertMode = false;
      if (!parkingTicketEntity) {
        insertMode = true;
        parkingTicketEntity = new ParkingTicket();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && parkingTicketEntity.last_modified < parkingTicket.last_modified)) {
        parkingTicketEntity.id = parkingTicket.id;
        parkingTicketEntity.business_id = parkingTicket.business_id;
        parkingTicketEntity.location_id = parkingTicket.location_id;
        parkingTicketEntity.operator_id = parkingTicket.operator_id;
        parkingTicketEntity.parking_lot_id = parkingTicket.parking_lot_id;
        parkingTicketEntity.code = parkingTicket.code;
        parkingTicketEntity.vehicle_plate_number = parkingTicket.vehicle_plate_number;
        parkingTicketEntity.vehicle_color = parkingTicket.vehicle_color;
        parkingTicketEntity.vehicle_make = parkingTicket.vehicle_make;
        parkingTicketEntity.contact_name = parkingTicket.contact_name;
        parkingTicketEntity.contact_phone = parkingTicket.contact_phone;
        parkingTicketEntity.status = parkingTicket.status;
        parkingTicketEntity.entered_at = parkingTicket.entered_at;
        parkingTicketEntity.exited_at = parkingTicket.exited_at;
        parkingTicketEntity.metadata = parkingTicket.metadata ? JSON.stringify(parkingTicket.metadata) : null;
        parkingTicketEntity.created_at = parkingTicket.created_at;
        parkingTicketEntity.updated_at = parkingTicket.updated_at;
        parkingTicketEntity.last_modified = parkingTicket.last_modified;
        parkingTicketEntity.isSynced = true;
        await dbConnection.getRepository(ParkingTicket).save(parkingTicketEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedParkingTickets = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const parkingTickets = await dbConnection
    .getRepository(ParkingTicket)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return parkingTickets.length > 0 ? parkingTickets : null;
}