import { defineStore, acceptHMRUpdate } from 'pinia';
interface State {
  sqlite: any;
  database: string | null;
  connection: Array<any> | null;
  inUse: boolean;
}

export const useDBConnectionStore = defineStore('dbConnection', {

  state: (): State => ({ 
    sqlite: null,
    database: null,
    connection: null,
    inUse: false,
  }),

  getters: {
    //
  },

  actions: {
    setSQLite(sqlite: any) {
      this.sqlite = sqlite;
    },
    setDatabase(database: string) {
      this.database = database;
    },
    setConnection(connection: any) {
      this.connection = connection;
    },
    resetConnection() {
      this.connection = null;
    },
    setInUse(inUse: boolean) {
      this.inUse = inUse;
    },
  }
});



if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useDBConnectionStore, import.meta.webpackHot));
}