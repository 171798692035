import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { InventoryPurchaseOrderItem } from '@/entity/InventoryPurchaseOrderItem';

export const mergeInventoryPurchaseOrderItems = async (businessId: any, locationId: any, inventoryPurchaseOrderItems: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching inventory purchase order items data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (inventoryPurchaseOrderItems && inventoryPurchaseOrderItems.length > 0) {
    for (const inventoryPurchaseOrderItem of inventoryPurchaseOrderItems) {
      let inventoryPurchaseOrderItemEntity = await dbConnection
        .getRepository(InventoryPurchaseOrderItem)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: inventoryPurchaseOrderItem.id })
        .getOne();
      let insertMode = false;
      if (!inventoryPurchaseOrderItemEntity) {
        insertMode = true;
        inventoryPurchaseOrderItemEntity = new InventoryPurchaseOrderItem();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && inventoryPurchaseOrderItemEntity.last_modified < inventoryPurchaseOrderItem.last_modified)) {
        inventoryPurchaseOrderItemEntity.id = inventoryPurchaseOrderItem.id;
        inventoryPurchaseOrderItemEntity.business_id = inventoryPurchaseOrderItem.business_id;
        inventoryPurchaseOrderItemEntity.location_id = inventoryPurchaseOrderItem.location_id;
        inventoryPurchaseOrderItemEntity.inventory_purchase_order_id = inventoryPurchaseOrderItem.inventory_purchase_order_id;
        inventoryPurchaseOrderItemEntity.inventory_item_id = inventoryPurchaseOrderItem.inventory_item_id;
        inventoryPurchaseOrderItemEntity.quantity = inventoryPurchaseOrderItem.quantity;
        inventoryPurchaseOrderItemEntity.cost_price = inventoryPurchaseOrderItem.cost_price;
        inventoryPurchaseOrderItemEntity.metadata = inventoryPurchaseOrderItem.metadata ? JSON.stringify(inventoryPurchaseOrderItem.metadata) : null;
        inventoryPurchaseOrderItemEntity.created_at = inventoryPurchaseOrderItem.created_at;
        inventoryPurchaseOrderItemEntity.updated_at = inventoryPurchaseOrderItem.updated_at;
        inventoryPurchaseOrderItemEntity.last_modified = inventoryPurchaseOrderItem.last_modified;
        inventoryPurchaseOrderItemEntity.isSynced = true;
        await dbConnection.getRepository(InventoryPurchaseOrderItem).save(inventoryPurchaseOrderItemEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedInventoryPurchaseOrderItems = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const inventoryPurchaseOrderItems = await dbConnection
    .getRepository(InventoryPurchaseOrderItem)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return inventoryPurchaseOrderItems.length > 0 ? inventoryPurchaseOrderItems : null;
}