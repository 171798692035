import { ref } from 'vue';
import { format, parseISO } from 'date-fns';
import { Decimal } from 'decimal.js';
import numeral from 'numeral';


/**
 * Parse amount
 * @param amount 
 * @param returnInstanceOfDecimal 
 * @returns 
 */
export function parseAmount(amount: any, returnInstanceOfDecimal = false, returnParseFloat = false): any {
  let result: any = 0;
  if (!amount) {
    amount = '0';
  }
  const amt = parseFloat(amount.toString().replace(/[^0-9.-]/g, ''));
  if (returnInstanceOfDecimal) {
    result = new Decimal(amt);
  } else {
    result = new Decimal(amt).toString();
  }
  return returnParseFloat ? amt : result;
}

/**
 * Format amount
 * @param amount 
 * @param currencySymbol 
 * @returns 
 */
export function formatAmount(amount: any, currencySymbol = '$'): string {
  let nospace = '';
  if (currencySymbol.length === 3 && currencySymbol.match(/[A-Z]/gi)) {
    nospace = ' ';
  }
  if (amount) {
    return currencySymbol + nospace + numeral(amount).format('0,0.00');
  } else {
    return currencySymbol + nospace + '0.00';
  }
}

/**
 * Format Number
 * @param number
 * @returns
 */
export function formatNumber(number: any): string {
  if (number) {
    return numeral(number).format('0,0');
  } else {
    return '0';
  }
}


/**
 * Format date
 * @param date 
 * @param showTime 
 * @param overrideDateFormat 
 * @returns 
 */
export function formatDate(date: any, showTime = true, overrideDateFormat = null): string {
  if (date) {
    if (typeof date === 'string') {
      date = parseISO(date);
    } else if (typeof date === 'number') {
      date = new Date(date);
    } else if (typeof date === 'object') {
      date = new Date(date.getTime());
    } else {
      date = new Date(date);
    }
    if (overrideDateFormat) {
      return format(date, overrideDateFormat);
    } else {
      if (showTime) {
        return format(date, 'PPpp');
      } else {
        return format(date, 'PP');
      }
    }
  } else {
    return '';
  }
}


/**
 * Sleep
 */
export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Periodically call a function
 */
export async function periodicRun(callback: any, delay: number, runWhile = ref(true)) {
  if (runWhile.value) { 
    if (typeof callback === 'function') {
      await callback();
    }
    // call recursively after timeout delay 
    setTimeout(() => {
      periodicRun(callback, delay, runWhile);
    }, delay);
  } else {
    return;
  }
}
