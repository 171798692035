import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { BookingOrder } from '@/entity/BookingOrder';

export const mergeBookingOrders = async (businessId: any, locationId: any, bookingOrders: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching booking orders data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (bookingOrders && bookingOrders.length > 0) {
    for (const bookingOrder of bookingOrders) {
      let bookingOrderEntity = await dbConnection
        .getRepository(BookingOrder)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: bookingOrder.id })
        .getOne();
      let insertMode = false;
      if (!bookingOrderEntity) {
        insertMode = true;
        bookingOrderEntity = new BookingOrder();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && bookingOrderEntity.last_modified < bookingOrder.last_modified)) {
        bookingOrderEntity.id = bookingOrder.id;
        bookingOrderEntity.business_id = bookingOrder.business_id;
        bookingOrderEntity.location_id = bookingOrder.location_id;
        bookingOrderEntity.operator_id = bookingOrder.operator_id;
        bookingOrderEntity.inventory_location_id = bookingOrder.inventory_location_id;
        bookingOrderEntity.customer_id = bookingOrder.customer_id;
        bookingOrderEntity.code = bookingOrder.code;
        bookingOrderEntity.subtotal = bookingOrder.subtotal;
        bookingOrderEntity.tax = bookingOrder.tax;
        bookingOrderEntity.discount = bookingOrder.discount;
        bookingOrderEntity.total = bookingOrder.total;
        bookingOrderEntity.paid_amount = bookingOrder.paid_amount;
        bookingOrderEntity.payment_status = bookingOrder.payment_status;
        bookingOrderEntity.payment_method = bookingOrder.payment_method;
        bookingOrderEntity.notes = bookingOrder.notes;
        bookingOrderEntity.status = bookingOrder.status;
        bookingOrderEntity.scheduled_delivery_at = bookingOrder.scheduled_delivery_at;
        bookingOrderEntity.delivered_at = bookingOrder.delivered_at;
        bookingOrderEntity.metadata = bookingOrder.metadata ? JSON.stringify(bookingOrder.metadata) : null;
        bookingOrderEntity.created_at = bookingOrder.created_at;
        bookingOrderEntity.updated_at = bookingOrder.updated_at;
        bookingOrderEntity.last_modified = bookingOrder.last_modified;
        bookingOrderEntity.isSynced = true;
        await dbConnection.getRepository(BookingOrder).save(bookingOrderEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedBookingOrders = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const bookingOrders = await dbConnection
    .getRepository(BookingOrder)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return bookingOrders.length > 0 ? bookingOrders : null;
}