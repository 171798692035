import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { ReservationFacility } from '@/entity/ReservationFacility';

export const mergeReservationFacilities = async (businessId: any, locationId: any, reservationFacilities: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching reservation facilities data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (reservationFacilities && reservationFacilities.length > 0) {
    for (const reservationFacility of reservationFacilities) {
      let reservationFacilityEntity = await dbConnection
        .getRepository(ReservationFacility)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: reservationFacility.id })
        .getOne();
      let insertMode = false;
      if (!reservationFacilityEntity) {
        insertMode = true;
        reservationFacilityEntity = new ReservationFacility();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && reservationFacilityEntity.last_modified < reservationFacility.last_modified)) {
        reservationFacilityEntity.id = reservationFacility.id;
        reservationFacilityEntity.business_id = reservationFacility.business_id;
        reservationFacilityEntity.location_id = reservationFacility.location_id;
        reservationFacilityEntity.reservation_category_id = reservationFacility.reservation_category_id;
        reservationFacilityEntity.name = reservationFacility.name;
        reservationFacilityEntity.description = reservationFacility.description;
        reservationFacilityEntity.capacity = reservationFacility.capacity;
        reservationFacilityEntity.photo = reservationFacility.photo;
        reservationFacilityEntity.status = reservationFacility.status;
        reservationFacilityEntity.is_enabled = reservationFacility.is_enabled;
        reservationFacilityEntity.metadata = reservationFacility.metadata ? JSON.stringify(reservationFacility.metadata) : null;
        reservationFacilityEntity.created_at = reservationFacility.created_at;
        reservationFacilityEntity.updated_at = reservationFacility.updated_at;
        reservationFacilityEntity.last_modified = reservationFacility.last_modified;
        reservationFacilityEntity.isSynced = true;
        await dbConnection.getRepository(ReservationFacility).save(reservationFacilityEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedReservationFacilities = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const reservationFacilities = await dbConnection
    .getRepository(ReservationFacility)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return reservationFacilities.length > 0 ? reservationFacilities : null;
}