import { useSyncStatusStore } from '@/stores/syncStatusStore';
import { getDBConnection } from '@/data-source';
import { LaundryOrderItem } from '@/entity/LaundryOrderItem';

export const mergeLaundryOrderItems = async (businessId: any, locationId: any, laundryOrderItems: any) => {
  const syncStatusStore = useSyncStatusStore();
  syncStatusStore.updateSyncPullProgressMessage('Synching laundry order items data... Please wait.');

  const dbConnection: any = await getDBConnection();
  if (laundryOrderItems && laundryOrderItems.length > 0) {
    for (const laundryOrderItem of laundryOrderItems) {
      let laundryOrderItemEntity = await dbConnection
        .getRepository(LaundryOrderItem)
        .createQueryBuilder()
        .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
        .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
        .andWhere('LOWER(id) = LOWER(:id)', { id: laundryOrderItem.id })
        .getOne();
      let insertMode = false;
      if (!laundryOrderItemEntity) {
        insertMode = true;
        laundryOrderItemEntity = new LaundryOrderItem();
      }
      // insert new or compare last modified time and update most recent
      if (insertMode === true || (insertMode === false && laundryOrderItemEntity.last_modified < laundryOrderItem.last_modified)) {
        laundryOrderItemEntity.id = laundryOrderItem.id;
        laundryOrderItemEntity.business_id = laundryOrderItem.business_id;
        laundryOrderItemEntity.location_id = laundryOrderItem.location_id;
        laundryOrderItemEntity.laundry_order_id = laundryOrderItem.laundry_order_id;
        laundryOrderItemEntity.laundry_service_list_id = laundryOrderItem.laundry_service_list_id;
        laundryOrderItemEntity.quantity = laundryOrderItem.quantity;
        laundryOrderItemEntity.amount = laundryOrderItem.amount;
        laundryOrderItemEntity.metadata = laundryOrderItem.metadata ? JSON.stringify(laundryOrderItem.metadata) : null;
        laundryOrderItemEntity.created_at = laundryOrderItem.created_at;
        laundryOrderItemEntity.updated_at = laundryOrderItem.updated_at;
        laundryOrderItemEntity.last_modified = laundryOrderItem.last_modified;
        laundryOrderItemEntity.isSynced = true;
        await dbConnection.getRepository(LaundryOrderItem).save(laundryOrderItemEntity);
      }
    }
  }
  syncStatusStore.updateSyncPullProgress();
};


export const getUnsyncedLaundryOrderItems = async (businessId: any, locationId: any) => {
  const dbConnection: any = await getDBConnection();
  const laundryOrderItems = await dbConnection
    .getRepository(LaundryOrderItem)
    .createQueryBuilder()
    .where('LOWER(business_id) = LOWER(:businessId)', { businessId: businessId })
    .andWhere('LOWER(location_id) = LOWER(:locationId)', { locationId: locationId })
    .andWhere("isSynced = :isSynced", { isSynced: false })
    .getMany();
  return laundryOrderItems.length > 0 ? laundryOrderItems : null;
}